import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "@emotion/styled/macro";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  resultsDisplay: {
    height: "1.85rem",
    fontSize: "1rem",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(253,226,106,1)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

export default function DisplayTable({
  columnNames = [],
  cellValues = [],
  tableFor = "",
}) {
  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      sx={
        tableFor === "resultsDisplay"
          ? { maxHeight: "14rem" }
          : { maxHeight: "10rem" }
      }
      className="table-data-container"
    >
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow className={classes[tableFor]}>
            {columnNames.map((column, index) => (
              <StyledTableCell
                key={`${column} ${index}`}
                style={{
                  fontSize: "0.85rem",
                  padding: "0.35em",
                  marginLeft: "auto",
                  fontWeight: "bold",
                }}
              >
                {column}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes[tableFor]}>
          {cellValues.map((row, index_i) => (
            <TableRow
              className={classes[tableFor]}
              key={`${row} ${index_i}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {row.map((cellValue, index_j) => {
                return (
                  <TableCell
                    key={`${cellValue} ${index_j}`}
                    style={{ fontSize: "0.75rem",fontWeight:"bold", padding: "0.5em",justifyContent:"center", display:"table-cell"}}
                    className="display-table-cell"
                  >
                    {cellValue}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
