import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import "./App.css";
import ApplicationRoutes from "./ApplicationRoutes";

const theme = createTheme();

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="App">
          <ApplicationRoutes />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
