import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import DisplayTable from "../DisplayTable/DisplayTable";

import {
  END_DATE,
  PROFICIENCY,
  RESOURCE_GROUP,
  SKILLS,
  START_DATE,
} from "../../../const";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

const useStyles = makeStyles((theme) => ({
  inputDescHead: {
    fontSize: "0.9rem",
    fontWeight: 600,
    color: "#000",
    padding: "0.5em",
    background: "rgb(224,209,224)",
    margin: "auto",
    width: "10em",
    borderRadius: "0.7em",
    textDecoration: "underline",
    float: "left",
  },
  descField: {
    width: "100%",
    height: "3.5rem",
    padding: "0.5em",
    border: "0.1em solid rgba(131,71,131, 0.3)",
    borderRadius: "0.5em",
    marginBottom: "1em",
  },
  descField1: {
    width: "100%",
    height: "auto",
    padding: "0.5em",
    border: "0.1em solid rgba(131,71,131, 0.3)",
    borderRadius: "0.5em",
    marginBottom: "1em",
  },
  resource_group: {
    fontSize: "0.9rem",
    fontWeight: 600,
    padding: "0.6em",
    width: "auto",
    marginTop: "0.2em",
    borderRadius: "0.7em",
    background: "#66c5d2",
    color: "#3363A0",
    display: "inline-block",
  },
  resource_groups_container: {
    overflowY: "scroll", 
    height: "7rem",
  },


}));

export default function DisplayUserInput() {
  const { listSkills, capCenter, fromDate, toDate, hub, role } =
    useCustomSearchParams();

  const res = [];
  listSkills.forEach((skill) => {
    res.push(Object.values(skill));
  });

  const classes = useStyles();
  const inputCapcenter = capCenter.map((capcenter) => capcenter);

  return (
    <div sx={{ display: "flex" }}>
      <Grid container spacing={2} justifyContent={"center"} xs={12} item>
        <Grid height={1} item xs={12} md={5}>
          <p className={classes.descField1}>
            <span className={classes.inputDescHead}>
              {RESOURCE_GROUP}s
            </span>
            <div className={classes.resource_groups_container}>
              {inputCapcenter.map((capcenter, cc_index) => (
                <span key={cc_index} className={classes.resource_group}>{capcenter}</span>
              ))}
            </div>
          </p>
        </Grid>
        <Grid item xs={12} md={5} minWidth={12}>
          <p className={classes.descField}>
            <span className={classes.inputDescHead}>{START_DATE}</span>
            <span className="user-input-resource-group">{fromDate}</span>
          </p>
          <p className={classes.descField}>
            <span className={classes.inputDescHead}>{END_DATE}</span>
            <span className="user-input-resource-group">{toDate}</span>
          </p>
        </Grid>
        <Grid item xs={12} md={5} minWidth={12}>
        <p className={classes.descField}>
            <span className={classes.inputDescHead}>Resource Role</span>
            <span className="user-input-resource-group">{role}</span>
          </p>
          <p className={classes.descField}>
            <span className={classes.inputDescHead}>Hub</span>
            <span className="user-input-resource-group">{hub}</span>
          </p>

        </Grid>
        <Grid item xs={12} md={5}>
          <DisplayTable
            className="skills-proficiency-table"
            columnNames={[SKILLS, PROFICIENCY]}
            cellValues={res}
          />
        </Grid>
      </Grid>
    </div>
  );
}
