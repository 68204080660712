import { makeStyles } from "@mui/styles";
import React from "react";
import DisplayMessage from "../DisplayMessage/DisplayMessage";
import { Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles((theme) => ({
    rg_container :{
        marginTop: "2rem",
        height: "7rem", /* Set the desired fixed height */
        border: "0.08em solid rgba(127, 127, 127, 0.8)",
        borderRadius: "5px",
        width: "50vw",
        overflowY: "scroll",
    },
    rg_names:{
        fontSize: "0.8rem",
        backgroundColor: "rgba(0, 60, 136, 0.1)",
        /* padding:auto; */
        listStyle: "none",
        marginLeft: "2em",
        paddingBottom: "0.2em",
    },
    rg_list:
    {
        listStyle: "none",
    },
    options_container:{
        display: "flex",
        flexWrap: "wrap"
    }
}));

export default function DisplayInputs({capCenter, marginSmall=6, marginLarge=6,marginMedium=6, handleDelete, displayMessage}) {
    const classes = useStyles();
    return (
       <Grid item xs={12} sm={marginSmall} md={marginMedium} lg={marginLarge} xl={marginLarge} className={classes.rg_container} mt={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <DisplayMessage
                  message={displayMessage}
                  />
              </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} className={classes.options_container}>
           {capCenter &&
              capCenter.map((skill) => (
                <li key={skill} className={classes.rg_list}>
                  <span className="rg_names">{skill.slice(0,30)}
                    <button className="delete_btn" onClick={() => handleDelete(skill)}>
                      <CloseIcon/>
                    </button>
                  </span>
                </li>
              ))
            }
            </Grid>
       </Grid>
    );
    }