import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Search from "./Search";
import SearchResult from "./SearchResult";
import SearchError from "./SearchError";

import { fetchAllResult } from "../../services/api";

export default function SearchContainer(props) {
  // Declare a new state variable, which we'll call isClicked"

  const [isClicked, setClicked] = useState(false);
  const [responseSet, setResponseSet] = useState(false);
  const [isError, setError] = useState(false);
  const [isCorsError, setCorsError] = useState(false);
  const [rpNumber, setrpNumber] = useState("");
  const [response, setResponse] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.urlrplnumber) {
      setrpNumber(props.urlrplnumber);
      setClicked(true);
      var patt = /RPLN[0-9]{7}$/i;
      var result = props.urlrplnumber.match(patt);
      if (result !== null && result.length === 1) {
        setError(false);
        navigate(`/RPLN/${props.urlrplnumber}`);

        setCorsError(false);
        fetchAllResult(props.urlrplnumber)
          .then((data) => {
            setResponseSet(false);
            // console.log("data keyPress->", data);
            if (data.data !== undefined) {
              setResponse(data.data);
            } else {
              setCorsError(true);
            }
          })
          .catch((error) => {
            console.log("error keyPress->", error);
          });
      } else {
        setError(true);
      }
    }
  }, [props.urlrplnumber, navigate]); //This line is IMPORTANT since the [] makes sure it is also called only once !!!!
  useEffect(() => {}, [response]); //This line is IMPORTANT since the [] makes sure it is also called only once !!!!
  const onClickfunction = (e) => {
    setResponse({});
    setClicked(true);
    setResponseSet(true);
    var patt = /RPLN[0-9]{7}$/i;
    var result = rpNumber.match(patt);
    if (result !== null && result.length === 1) {
      navigate(`/RPLN/${rpNumber}`);
      setError(false);
      setCorsError(false);
      fetchAllResult(rpNumber)
        .then((data) => {
          setResponseSet(false);
          console.log("data keyPress->", data);
          if (data.data !== undefined) {
            setResponse(data.data);
          } else {
            setCorsError(true);
          }
        })
        .catch((error) => {
          console.log("error keyPress->", error);
        });
    } else {
      setError(true);
    }
  };
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      setResponse({});
      setClicked(true);
      setResponseSet(true);
      e.preventDefault();
      var patt = /RPLN[0-9]{7}$/i;
      var result = rpNumber.match(patt);
      if (result !== null && result.length === 1) {
        navigate(`/RPLN/${rpNumber}`);
        setError(false);
        setCorsError(false);
        fetchAllResult(rpNumber)
          .then((data) => {
            setResponseSet(false);
            console.log("data keyPress->", data);
            if (data.data !== undefined) {
              setResponse(data.data);
            } else {
              setCorsError(true);
            }
          })
          .catch((error) => {
            console.log("error keyPress->", error);
          });
      } else {
        setError(true);
      }
    }
  };
  return (
    <div>
      {!isClicked && (
        <Search
          setClicked={setClicked}
          isClicked={isClicked}
          setrpNumber={setrpNumber}
          rpNumber={rpNumber}
          setResponse={setResponse}
          response={response}
          onKeyPress={(e) => {
            keyPress(e);
          }}
          onClickfunction={(e) => {
            onClickfunction(e.target.value);
          }}
        />
      )}

      {isClicked && !(isError || isCorsError) && (
        <SearchResult
          setClicked={setClicked}
          isClicked={isClicked}
          setResponseSet={setResponseSet}
          responseSet={responseSet}
          setrpNumber={setrpNumber}
          rpNumber={rpNumber}
          setResponse={setResponse}
          response={response}
          onKeyPress={keyPress}
          onClickfunction={onClickfunction}
        />
      )}

      {isClicked && (isError || isCorsError) && (
        <SearchError
          setClicked={setClicked}
          isClicked={isClicked}
          setrpNumber={setrpNumber}
          rpNumber={rpNumber}
          setResponse={setResponse}
          response={response}
          isCorsError={isCorsError}
          onKeyPress={keyPress}
          onClickfunction={onClickfunction}
        />
      )}
    </div>
  );
}
