import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import "./LandingDisplayCard.css";

import Logo from "../../asset/rplnPage.png";

export default function LandingDisplayCard({
  image = Logo,
  title,
  description,
  cardFor = "Skills-Search",
  addMsg = "",
}) {
  return (
    <Card
      sx={{ maxWidth: 345, maxHeight: 345 }}
      className={"Card-Layout " + cardFor}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="100"
          style={{ objectFit: "contain" }} // Add objectFit style property
          image={image}
          alt="RPLN based Search"
        />
        <CardContent>
          <Typography
            style={{ fontWeight: "600", color: "#009eb4" }}
            gutterBottom
            variant="body1"
            component="div"
          >
            {title}
          </Typography>
          <Typography
            style={{ fontWeight: "400" }}
            variant="body3"
            color="text.secondary"
          >
            {description}
          </Typography>
          <p>{addMsg}</p>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
