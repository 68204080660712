import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  labelContentDiv: {
    marginLeft: "1em",
    alignItems: "center",
    marginTop: "0.5em",
    fontWeight: "bold",
  },
  labelHead: {
    fontSize: "1em",
  },
  labelContent: {
    fontSize: "0.7em",
  },
  labelDivider: {
    border: "0.1em solid #fbce07",
    opacity: "1",
  },
}));

const LabelContent = ({
  labelHeading = "",
  labelValue = "",
  hr_width = 0,
  children,
}) => {
  const classes = useStyles();
  const removesSquareBraces =
    labelValue.includes("]") || labelValue.includes(";")
      ? labelValue.replace("[", "").replace("]", "")
      : labelValue;
  const newLabelValueArray = [...new Set(removesSquareBraces.split(";"))];

  return (
    <div className={classes.labelContentDiv}>
      <Typography
        style={{ fontSize: "0.9rem", fontWeight: "bold" }}
        mt={0}
        align={labelValue === "" ? "center" : "justify"}
      >
        {labelHeading}
      </Typography>
      {hr_width > 0 ? (
        <hr
          className={classes.labelDivider}
          style={{ width: `${hr_width}%`, marginTop: "0em" }}
        />
      ) : (
        ""
      )}
      <Typography className={classes.labelContent} variant="body2" mr={3}>
        {newLabelValueArray.map((value, index) => {
          if (value.includes("'")) value = value.replace(/'/g, "");
          return (
            <span key={index}>
              {value}
              {index < newLabelValueArray.length - 1 ? ", " : ""}
            </span>
          );
        })}
      </Typography>
      <div className={classes.labelContent}>{children}</div>
    </div>
  );
};

export default LabelContent;
