import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container, Divider } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import DisplayUserInput from "../DisplayUserInput/DisplayUserInput";
import DisplayResultCard from "../DisplayResultCard/DisplayResultCard";
import ExcelExtract from "../JsonExcelExtract/ExcelExtract";
import { EMPLOYEE_TYPES, SEARCH_PARAMS } from "../../../const";
import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

export default function DisplayOutputPage({ query }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [employeeType, setEmployeeType] = useState("All");
  const {
    hub,
    capCenter,
    fromDate,
    toDate,
    validatedSkills,
    role,
    lob,
    listSkills,
  } = useCustomSearchParams();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://airesourcematchingprod.azurewebsites.net/getresourceSkillsBased/getResults?${query}`
 );
        setApiData(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [query]);
  
  const handleBackButton = () => {
    navigate(
      `/Skills?${SEARCH_PARAMS.CAPCENTER}=${encodeURIComponent(
        JSON.stringify(capCenter))}&${
        SEARCH_PARAMS.FROMDATE
      }=${fromDate}&${SEARCH_PARAMS.TODATE}=${toDate}&${
        SEARCH_PARAMS.VALIDATEDSKILLS
      }=${validatedSkills}&${SEARCH_PARAMS.ROLE}=${encodeURIComponent(role)}&${
        SEARCH_PARAMS.HUB
      }=${hub}&${SEARCH_PARAMS.LOB}=${encodeURIComponent(JSON.stringify(lob))}&${
        SEARCH_PARAMS.LISTSKILLS
      }=${encodeURIComponent(JSON.stringify(listSkills))}`
    );
  };

  const handleEmployeeType = (event) => {
    setEmployeeType(event.target.value);
  };

  const displayResources = useMemo(() => {
    const numberOfInputSkills = listSkills.length;
    const existUserWithAllSkills = apiData
      .slice(0, -1)
      .some((result) => result["Combined"] === numberOfInputSkills);
    if (existUserWithAllSkills) {
      return apiData
        .slice(0, -1)
        .filter((result) => result["Combined"] === numberOfInputSkills);
    } else return apiData.slice(0, -1);
  }, [apiData, listSkills]);

  const messages = useMemo(
    () => apiData[apiData.length - 1]?.["Add_messages"],
    [apiData]
  );
  let excelQuery = `/Skills?${SEARCH_PARAMS.CAPCENTER}=${encodeURIComponent(
        JSON.stringify(capCenter))}&${
        SEARCH_PARAMS.FROMDATE
      }=${fromDate}&${SEARCH_PARAMS.TODATE}=${toDate}&${
        SEARCH_PARAMS.VALIDATEDSKILLS
      }=${validatedSkills}&${SEARCH_PARAMS.ROLE}=${encodeURIComponent(role)}&${
        SEARCH_PARAMS.HUB
      }=${hub}&${SEARCH_PARAMS.LOB}=${encodeURIComponent(JSON.stringify(lob))}&${
        SEARCH_PARAMS.LISTSKILLS
      }=${encodeURIComponent(JSON.stringify(listSkills))}`;

  return (
    <div style={{marginBottom:"2em"}}>
      <button className="back_btn" onClick={handleBackButton}>
        <ArrowBackIcon /> Go Back
      </button>
      <Container
        className="display-input-container"
        maxWidth="lg"
        mb={5}
        style={{ marginBottom: "0em" }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <DisplayUserInput />
              <Divider orientation="vertical" flexItem />
            </Grid>
            {loading ? (
              <div
                style={{
                  marginTop: "1rem",
                  width: "100vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            ) : (
              <>
                <Grid container >
                {displayResources.length>0 &&
                <>
                  <Grid item xs={9} md={9} sm={9} lg={9}>
                  <FormControl>
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      sx={{ color: "black" }}
                    >
                      Employee Type
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={employeeType}
                      onChange={handleEmployeeType}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <FormControlLabel
                        value={EMPLOYEE_TYPES.Staff}
                        control={<Radio />}
                        label={EMPLOYEE_TYPES.Staff}
                      />
                      <FormControlLabel
                        value={EMPLOYEE_TYPES.Contractor}
                        control={<Radio />}
                        label={EMPLOYEE_TYPES.Contractor}
                      />
                      <FormControlLabel
                        value={EMPLOYEE_TYPES.All}
                        control={<Radio />}
                        label={EMPLOYEE_TYPES.All}
                        defaultChecked
                      />
                    </RadioGroup>
                  </FormControl>
                  </Grid>
                    <Grid item xs={3} md={3} lg={3} sm={3} >
                    <ExcelExtract 
                    responseData={displayResources
                    ?.filter(
                      (result) =>
                        employeeType === EMPLOYEE_TYPES.All ||
                        result["Employee Type"] === employeeType
                    )} 
                    query={excelQuery} />
                  </Grid>
                  </>}
                  <Grid item xs={12} md={12} sm={12} lg={12} mt={2}>
                    {Array.isArray(messages) ? (
                      messages?.map((msg) => <p key={msg}>{msg}</p>)
                    ) : (
                      <p key={messages}>{messages}</p>
                    )}
                  </Grid>

                </Grid>
                <Grid container item xs={12} md={12} >
                  {displayResources
                    ?.filter(
                      (result) =>
                        employeeType === EMPLOYEE_TYPES.All ||
                        result["Employee Type"] === employeeType
                    )
                    .sort((a, b) => {
                      if (a["Combined"] === b["Combined"])
                        return b["Available Hours"] - a["Available Hours"];
                      return b.Combined - a.Combined;
                    })
                    .map((result, index) => {;
                      return (
                        <Grid item xs={12} md={6} key={`${result} ${index}`}>
                          <DisplayResultCard result={result} />
                        </Grid>
                      );
                    })}
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
