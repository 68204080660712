import {useParams } from "react-router-dom";

import SearchContainer from "./component/main/SearchContainer";
import ButtonAppBar from "./component/common/ButtonAppBar";

const ReturnSearch = () => {
  const { urlrplnumber } = useParams();

  return (
    <div className="App">
      <ButtonAppBar />
      <SearchContainer urlrplnumber={urlrplnumber} />
    </div>
  );
};

export default ReturnSearch;
