import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { Autocomplete, Typography } from "@mui/material";

import { INPUT_AUTO_COMPLETE_TYPE } from "../../../const";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  ccInput: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    marginTop: "1rem",
    marginBottom: "1rem",
    height: "2.3em",
  },
  label: {
    background: "#FCD839",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.40rem 0.55rem 0.40rem 0.55rem",
    fontSize: "0.8em",
    fontWeight: 500,
    color: "#000",
  },
  autoInput: {
    fontSize: "0.5em",
  }
}));

export default function CapabilityCenter() {
  const classes = useStyles();
  const { lob, setLob, setCapCenter, groups } = useCustomSearchParams();
  const keys = groups? Object.keys(groups) : [];

  const [resourceGroup, setResourceGroup] = useState("Any");
  const [parentResourceGroup, setParentResourceGroup] = useState("Any");
 
  const options = groups && lob.reduce(
    (oldList, rg) => [...oldList, ...groups[rg]], []
  ) ? lob.reduce((oldList,rg)=>[...oldList, ...groups[rg]],["Any"])  : [];
  
 const handleParentResourceGroupChange = (e, value) => {
    if (value !== "") {
      setParentResourceGroup(value);
      setLob(value);
    }
    else {
      setParentResourceGroup("Any");
      setLob("Any");
    }
  }
  const handleResourceGroupChange = (e, value) => {
    if (value !== "") {
      setResourceGroup(value);
      setCapCenter(value); // Update capCenter directly with the new value
    }
    else {
      setResourceGroup("Any");
      setCapCenter("Any");
    }
  }
  return (
    <>
      <div className={`${classes.ccInput}`}>
        <Typography className={classes.label}>Parent Resource Group</Typography>
        <Autocomplete
          value={parentResourceGroup}
          options={keys}
          onChange={handleParentResourceGroupChange}
          sx={{ width: 300 }}
          type={INPUT_AUTO_COMPLETE_TYPE[1]}
          placeholder="Line of Business"
          className={classes.autoInput}
          renderInput={(params) => (
            <TextField {...params} />
          )}
          />

        <Typography className={classes.label}>Resource Group</Typography>
        <Autocomplete
          value={resourceGroup}
          options={options}
          sx={{ width: 300 }}
          onChange={handleResourceGroupChange}
          type={INPUT_AUTO_COMPLETE_TYPE[1]}
          className={classes.autoInput}
          renderInput={(params) => (
            <TextField {...params} />
          )}

        />
      </div>
    </>
  );
}
