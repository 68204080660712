import React from 'react'

import { makeStyles } from '@mui/styles';

const useStyles=makeStyles((theme)=>({
  DisplayMessage:
{
      color: "#834783",
      marginLeft: "auto"
}}));

export default function DisplayMessage({message}) {
  const classes=useStyles();
  return (
    <div className={classes.DisplayMessage}>
      {message}
    </div>
  )
}
