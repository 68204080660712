import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    border: "1px solid rgb(222, 226, 230)",
    fontSize: "1em",
    padding: "0.5em",
    "&:focus": {
      outline: "none",
      borderColor: theme.palette.primary,
      boxShadow: `0 0 0 0.8px ${theme.palette.primary.main}`,
    },
  },
}));

export default function InputAutocomplete({
  setFunction,
  options,
  placeholder = "",
}) {
  const classes = useStyles();
  const handleChange = (v) => setFunction(v);

  return (
    <Autocomplete
      sx={{
        display: "inline-block",
        "& input": {
          width: 300,
          marginLeft: "0.3em",
          marginRight: "0.3em",
          height: "2.6rem",
          fontSize: "0.8em",
          bgcolor: "background.paper",
          color: (theme) =>
            theme.palette.getContrastText(theme.palette.background.paper),
        },
      }}
      onChange={(e, v) => handleChange(v)}
      id="custom-input-demo"
      options={options}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <input
            type="text"
            {...params.inputProps}
            className={classes.autocomplete}
            placeholder={placeholder}
          />
        </div>
      )}
    />
  );
}
