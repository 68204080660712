import React from "react";

import "./SkillsResultPage.css";
import Header from "../../component/SkillsSearch/Header/Header";
import DisplayOutPutPage from "../../component/SkillsSearch/DisplayOutputPage/DisplayOutputPage";
import useCustomSearchParams from "../../hooks/useCustomSearchParams";

export default function SkillsResultPage() {
  const {
    listSkills,
    capCenter,
    hub,
    role,
    fromDate,
    toDate,
    validatedSkills,
    lob,
  } = useCustomSearchParams();

  const skills_prof = JSON.stringify(listSkills);
  const resourceGroups = JSON.stringify(capCenter);
  const lob_list = JSON.stringify(lob);
  const query = `ResourceGroups=${encodeURIComponent(
    resourceGroups
  )}&Skills=${encodeURIComponent(
    skills_prof
  )}&StartDate=${fromDate}&EndDate=${toDate}&Validated=${validatedSkills}&LoB=${encodeURIComponent(lob_list)}&Hub=${encodeURIComponent(
    hub
  )}&ResourceRole=${encodeURIComponent(role)}`;

  return (
    <div>
      <Header />
      <DisplayOutPutPage query={query} />
    </div>
  );
}
