import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ReturnSearch from "./ReturnSearch";

import HomePage from "./Pages/HomePage/HomePage";
import SkillsSearch from "./Pages/SkillsSearchPage/SkillsSearch";
import SkillsResultPage from "./Pages/SkillsResultPage/SkillsResultPage";

const ApplicationRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/RPLN" element={<ReturnSearch />} />
        <Route path="/RPLN/:urlrplnumber" element={<ReturnSearch />} />
        <Route path="/Skills/Result" element={<SkillsResultPage />} />
        <Route path="/Skills" element={<SkillsSearch />} />
      </Routes>
    </Router>
  );
};

export default ApplicationRoutes;
