import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

import hubs from "./Hub";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    marginTop: "1rem",
    marginBottom: "1rem",
    height: "2.3em",
  },
  label: {
    background: "#FCD839",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "5rem",
    padding: "0.40rem 0.55rem 0.40rem 0.55rem",
    marginRight: "0.5em",
    fontSize: "0.8em",
    fontWeight: 500,
    color: "#000",
  },

  hubDropdown: {
    height: "2.3em",
    fontWeight: 500,
    borderColor: theme.palette.divider, // Add this line to use the theme's divider color
  },
}));

const HubComponent = () => {
  const classes = useStyles();
  const { hub, setHub } = useCustomSearchParams();

  const handleHubChange = (e) => setHub(e.target.value);

  return (
    <div className={classes.container}>
      <Typography className={classes.label}>Hub</Typography>
      <select
        className={classes.hubDropdown}
        value={hub}
        placeholder="--Select Hub--"
        onChange={handleHubChange}
      >
        {hubs.map((hub) => (
          <option value={hub} key={hub}>
            {hub}
          </option>
        ))}
      </select>
    </div>
  );
};

export default HubComponent;
