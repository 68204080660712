import React from "react";
import { Grid } from "@mui/material";

import LabelContent from "../LabelContent/LabelContent";

const DisplayGridContainer = ({
  LabelHeadingList = [],
  LabelValueList = [],
  columnSize = 4,
  hr_width = 0,
}) => {
  return (
    <Grid container m={0}>
      {LabelHeadingList.map((labelName, index) => {
        return (
          <Grid
            xs={columnSize}
            md={columnSize}
            lg={columnSize}
            key={`${labelName} ${index}`}
          >
            <LabelContent
              labelHeading={labelName}
              labelValue={LabelValueList[index]}
              hr_width={hr_width}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default DisplayGridContainer;
