import React from "react";

import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { makeStyles } from "@mui/styles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Availability Hours  v/s Month",
    },
  },
  scales: {
    y: {
      min: 0,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  chart: {
    width: "100%",
    height: "100%",
    "@media (max-width: 820px)": {
      width: "100%",
      height: "100%",
    },
  }

}));
export default function DisplayLineChart({ monthLabels = [], hoursData = [] }) {
  const classes = useStyles();
  return (
    <Line
      data={{
        labels: monthLabels,
        datasets: [
          {
            label: "Availability Hours",
            data: hoursData,
            fill: false,
            backgroundColor: "rgb(0,158,180)",
            borderColor: "rgba(0,158,180,0.5)",
          },
        ],
      }}

      options={options}
      className={classes.chart}
    />
  );
}
