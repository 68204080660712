import { makeStyles } from "@mui/styles";
import { Autocomplete, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    height: "2.3em",
  },
  label: {
    background: "#FCD839",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "5rem",
    padding: "0.40rem 0.55rem 0.40rem 0.55rem",
    marginRight: "0.5em",
    fontSize: "0.8em",
    fontWeight: 500,
    color: "#000",
  },

  roleDropdown: {
    fontWeight: 500,
    borderColor: theme.palette.divider, // Add this line to use the theme's divider color
  },
}));

const RoleComponent = () => {
  const classes = useStyles();

  const { role, setRole } = useCustomSearchParams();
  const { resourceRoleOptions } = useCustomSearchParams();

  const displayRoleList = resourceRoleOptions["Role"]?.sort((a, b) => a.localeCompare(b));

  const handleRoleChange = (e, value) => {
    if (value) setRole(value);
    else setRole("Any");
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.label}>Resource Role</Typography>
      <Autocomplete
        value={role}
        className={classes.roleDropdown}
        options={displayRoleList}
        renderInput={(params) => (
          <TextField {...params}/>
        )}
        sx={{
          width: 300,
        }}
        onChange={handleRoleChange}
      />
    </div>
  );
};

export default RoleComponent;
