import React from "react";
import { makeStyles } from "@mui/styles";

import Shell from "../../../asset/shell-logo.svg";
import Logo from "../../../asset/logo.png";
import Banner from "../../../asset/Banner.jpg";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    background: `url(${Banner})`, // Add the image URL here
    justifyContent: "space-between",
    alignItems: "center",
    height: "6rem",
    position: "sticky",
    marginBottom: "auto",
    opacity: 0.85,
  },
  logo: {
    height: "3rem",
    margin: "1em",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "space-between" /* Adjust this as needed */,
  },
}));

export default function Header() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.header}>
        <div className={classes.logoContainer}>
          <img src={Shell} className={classes.logo} alt="pecten" />
          <img src={Logo} className={classes.logo} alt="logo" />
        </div>
      </div>
    </div>
  );
}
