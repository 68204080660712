import { useSearchParams } from "react-router-dom";

import { RESOURCE_GROUP_ALL, SEARCH_PARAMS } from "../const";
import { threeMonthsLater, today } from "../component/utils";
// import groups from "../component/SkillsSearch/CapabilityCenter/ResourceGroup";


const useCustomSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const groups = JSON.parse(localStorage.getItem("ParentResourceGroups"));
  const skillOptions = JSON.parse(localStorage.getItem("Skills"));
  const resourceRoleOptions = JSON.parse(localStorage.getItem("ResourceRoles"));
  const listSkills = JSON.parse(
    decodeURIComponent(
      searchParams.get(SEARCH_PARAMS.LISTSKILLS) === null
        ? "[]"
        : searchParams.get(SEARCH_PARAMS.LISTSKILLS)
    )
  );

  const capCenter = JSON.parse(decodeURIComponent(
    searchParams.get(SEARCH_PARAMS.CAPCENTER) === null
      ? `["${RESOURCE_GROUP_ALL}"]`
      : searchParams.get(SEARCH_PARAMS.CAPCENTER)
  ));

  const fromDate = searchParams.get(SEARCH_PARAMS.FROMDATE) || today;
  const toDate = searchParams.get(SEARCH_PARAMS.TODATE) || threeMonthsLater;
  const validatedSkills =
    searchParams.get(SEARCH_PARAMS.VALIDATEDSKILLS) === "true" || false;

  const role = decodeURIComponent(
    searchParams.get(SEARCH_PARAMS.ROLE) === null
      ? "Any"
      : searchParams.get(SEARCH_PARAMS.ROLE)
  );
  const hub = searchParams.get(SEARCH_PARAMS.HUB) || "Any";

  const lob = JSON.parse(decodeURIComponent(
    searchParams.get(SEARCH_PARAMS.LOB) === null
      ? `["${(groups && Object.keys(groups)[0])}"]`
      : searchParams.get(SEARCH_PARAMS.LOB)
  ));

  const setParameters = (
    listSkills,
    capCenter,
    fromDate,
    toDate,
    validatedSkills,
    role,
    hub,
    lob
  ) => {
    const {
      LISTSKILLS,
      CAPCENTER,
      FROMDATE,
      TODATE,
      VALIDATEDSKILLS,
      ROLE,
      HUB,
      LOB,
    } = SEARCH_PARAMS;
    setSearchParams({
      [CAPCENTER]: encodeURIComponent(JSON.stringify(capCenter)),
      [FROMDATE]: fromDate,
      [TODATE]: toDate,
      [VALIDATEDSKILLS]: validatedSkills,
      [ROLE]: encodeURIComponent(role),
      [HUB]: hub,
      [LOB]: encodeURIComponent(JSON.stringify(lob)),
      [LISTSKILLS]: encodeURIComponent(JSON.stringify(listSkills)),
    });
  };

  const reset = () => {
    setParameters(
      [],
      [RESOURCE_GROUP_ALL],
      today,
      threeMonthsLater,
      false,
      "Any",
      "Any",
      [Object.keys(groups)[0]]
    );
  };

  const setCapCenter = (capCenter1) => {
    if(capCenter1 !== null && !capCenter.includes(capCenter1))
    {
      if(capCenter1 === "Any")
        {
          setParameters(
            listSkills,
            [RESOURCE_GROUP_ALL],
            fromDate,
            toDate,
            validatedSkills,
            role,
            hub,
            lob
          );
        }
        else if(capCenter.includes("Any"))
        {
          setParameters(
            listSkills,
            [capCenter1],
            fromDate,
            toDate,
            validatedSkills,
            role,
            hub,
            lob
          );
        }
        else
        {
          setParameters(
            listSkills,
            [...capCenter,capCenter1],
            fromDate,
            toDate,
            validatedSkills,
            role,
            hub,
            lob
          );
        }
    }
  };
  const deleteCapCenter  = (capCenterValue)=>{
    let newCapCenter = capCenter.filter((capCenter1) => capCenter1 !== capCenterValue);
    if(newCapCenter.length === 0)
    {
       newCapCenter = [RESOURCE_GROUP_ALL];
    }
    setParameters(
      listSkills,
      newCapCenter,
      fromDate,
      toDate,
      validatedSkills,
      role,
      hub,
      lob);
  }
  const setValidatedSkills = (validated) => {
    setParameters(
      listSkills,
      capCenter,
      fromDate,
      toDate,
      validated,
      role,
      hub,
      lob
    );
  };
  const setFromDate = (from) => {
    setParameters(
      listSkills,
      capCenter,
      from,
      toDate,
      validatedSkills,
      role,
      hub,
      lob
    );
  };
  const setToDate = (to) => {
    setParameters(
      listSkills,
      capCenter,
      fromDate,
      to,
      validatedSkills,
      role,
      hub,
      lob
    );
  };
  const setHub = (hubLocation) => {
    setParameters(
      listSkills,
      capCenter,
      fromDate,
      toDate,
      validatedSkills,
      role,
      hubLocation,
      lob
    );
  };
  const setRole = (resourceRole) => {
    setParameters(
      listSkills,
      capCenter,
      fromDate,
      toDate,
      validatedSkills,
      resourceRole,
      hub,
      lob
    );
  };
  const setLob = (lineOfBusiness1) => {
    if(lineOfBusiness1 !== null && !lob.includes(lineOfBusiness1))
      { 
        if(lineOfBusiness1 === "Any")
          { 
            setParameters(
              listSkills,
              capCenter,
              fromDate,
              toDate,
              validatedSkills,
              role,
              hub,
              [Object.keys(groups)[0]]
            );
          }
          else if(lob.includes("Any"))
          {
            setParameters(
              listSkills,
              capCenter,
              fromDate,
              toDate,
              validatedSkills,
              role,
              hub,
              [lineOfBusiness1]
            );
          }
          else
          {
            setParameters(
              listSkills,
              capCenter,
              fromDate,
              toDate,
              validatedSkills,
              role,
              hub,
              [...lob,lineOfBusiness1]
            );
          }
      }
  };

  const deleteLob  = (lobValue)=>{
    let newLob = lob.filter((lobValue1) => lobValue1 !== lobValue);
    let deleteLobCC = groups[lobValue];
    let newLobCC = newLob.reduce((oldList, rg) => [...oldList, ...groups[rg]], []);
    //eslint-disable-next-line
    let newCapCenter = capCenter.map((ccValue)=>{
    if((!deleteLobCC.includes(ccValue) && newLobCC.includes(ccValue)) || (deleteLobCC.includes(ccValue) && newLobCC.includes(ccValue)))
    {
         return ccValue;
    }}).filter((ccValue) => ccValue !== undefined);
    if(newCapCenter.length === 0)
    {
       newCapCenter = [RESOURCE_GROUP_ALL];
    }
    if(newLob.length === 0)
    {
       newLob = [Object.keys(groups)[0]];
    }

    setParameters(
      listSkills,
      newCapCenter,
      fromDate,
      toDate,
      validatedSkills,
      role,
      hub,
      newLob);
  }

  const setListSkills = ({ skill, proficiency }) => {
    if (listSkills.length === 0 && skill) {
      setParameters(
        [{ skill: skill, proficiency: proficiency }],
        capCenter,
        fromDate,
        toDate,
        validatedSkills,
        role,
        hub,
        lob
      );
    } else {
      if (skill) {
        const exist = listSkills?.find(
          (skillObj) => skillObj.skill?.toLowerCase() === skill?.toLowerCase()
        );
        if (!exist)
          setParameters(
            [...listSkills, { skill: skill, proficiency: proficiency }],
            capCenter,
            fromDate,
            toDate,
            validatedSkills,
            role,
            hub,
            lob
          );
        else {
          const newList = listSkills?.map((skillObj) =>
            skillObj.skill.toLowerCase() === skill.toLowerCase()
              ? { skill: skill, proficiency: proficiency }
              : skillObj
          );
          setParameters(
            newList,
            capCenter,
            fromDate,
            toDate,
            validatedSkills,
            role,
            hub,
            lob
          );
        }
      }
    }
  };
  const deleteSkill = (skill) => {
    const newList = listSkills.filter(
      (skillObj) => skillObj.skill?.toLowerCase() !== skill?.toLowerCase()
    );
    setParameters(
      newList,
      capCenter,
      fromDate,
      toDate,
      validatedSkills,
      role,
      hub,
      lob
    );
  };

  return {
    listSkills,
    capCenter,
    fromDate,
    toDate,
    validatedSkills,
    role,
    hub,
    lob,
    groups,
    skillOptions,
    resourceRoleOptions,
    deleteCapCenter,
    setParameters,
    reset,
    setCapCenter,
    setValidatedSkills,
    setListSkills,
    setFromDate,
    setToDate,
    setHub,
    setRole,
    setLob,
    deleteSkill,
    deleteLob,
  };
};
export default useCustomSearchParams;
