import React, {  useEffect ,useState } from "react";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DisplayMessage from "../../component/SkillsSearch/DisplayMessage/DisplayMessage";
import Search from "../../component/SkillsSearch/Search/Search";
import DisplaySkills from "../../component/SkillsSearch/DisplaySkills/DisplaySkills";
import Header from "../../component/SkillsSearch/Header/Header";
import CapabilityCenter from "../../component/SkillsSearch/CapabilityCenter/CapabilityCenter";
import HubComponent from "../../component/SkillsSearch/Hub/HubComponent";
import RoleComponent from "../../component/SkillsSearch/ResourceRoles/RoleComponent";
import "./SkillsSearch.css";
import useCustomSearchParams from "../../hooks/useCustomSearchParams";
import { SEARCH_PARAMS } from "../../const";
import { CircularProgress, Grid } from "@mui/material";
import DisplayInputs from "../../component/SkillsSearch/DisplayInputs/DisplayInputs";
import { fetchOptions } from "../../services/api";

export default function SkillsSearch() {
  const navigate = useNavigate();
  const {
    validatedSkills,
    lob,
    hub,
    role,
    capCenter,
    listSkills,
    reset,
    fromDate,
    toDate,
    deleteCapCenter,
    deleteLob
  } = useCustomSearchParams();

  const [loadedData, setLoadedData] = useState(false);


  useEffect(() => {
    const skills = localStorage.getItem("Skills");
    const parentResourceGroups = localStorage.getItem("ParentResourceGroups");

    if (skills && parentResourceGroups) {
      setLoadedData(true);
    } else {
      fetchOptions().then(() => setLoadedData(true)).catch((err) => console.log(err));
    }
  }, []);

  const handleNavigate = () => navigate("/");
  const handleGetResources = () => {
    navigate(
      `/Skills/Result?${SEARCH_PARAMS.CAPCENTER}=${encodeURIComponent(
        JSON.stringify(capCenter)
      )}&${SEARCH_PARAMS.FROMDATE}=${fromDate}&${SEARCH_PARAMS.TODATE
      }=${toDate}&${SEARCH_PARAMS.VALIDATEDSKILLS}=${validatedSkills}&${SEARCH_PARAMS.ROLE
      }=${encodeURIComponent(role)}&${SEARCH_PARAMS.HUB}=${encodeURIComponent(
        hub
      )}&${SEARCH_PARAMS.LOB}=${encodeURIComponent(JSON.stringify(lob))}&${SEARCH_PARAMS.LISTSKILLS
      }=${encodeURIComponent(JSON.stringify(listSkills))}`
    );
  };
  const handleReset = () => reset();

  const handleDelete = (rg) => {
    deleteCapCenter(rg);
  };

  const handleParentResourceGroupDelete = (parentResourceGroup) => {
    deleteLob(parentResourceGroup);
  }
  if (!loadedData) {
    return (
      <div>
        <Header />
        <button className="back_btn" onClick={handleNavigate}>
          <ArrowBackIcon /> Home
        </button>
        <div
          style={{
            marginTop: "1rem",
            width: "100vw",
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }
  return (
    <div>
      <Header />
      <button className="back_btn" onClick={handleNavigate}>
        <ArrowBackIcon /> Home
      </button>
      <Grid mb={2}>
        <CapabilityCenter />
      </Grid>
      <Grid className="displayRgsContainer" container>

        <DisplayInputs capCenter={lob} handleDelete={handleParentResourceGroupDelete} displayMessage={`You have selected ${lob.length
          } Parent Resource Group(s).`} />
        <hr></hr>
        <DisplayInputs capCenter={capCenter} handleDelete={handleDelete} displayMessage={`You have selected ${capCenter.length

          } Resource Group(s).`} />
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <HubComponent />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <RoleComponent />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DisplayMessage
            message={`You have selected ${hub ? hub : "Any"} for Hub and ${role ? role : "Any"} for Resource Role.`}
          />
        </Grid>
      </Grid>
      <p className="note">
        <span className="span-group-p">Note :</span>Resource Group , Resource
        Roles & Hub are optional. Skill and Proficiency is required.
      </p>
      <Search />

      {listSkills.length > 0 && (
        <>
          <DisplaySkills />
          <button className="ReviewButton" onClick={handleGetResources}>
            Get Resources
          </button>
          <button className="ReviewButton" onClick={handleReset}>
            Clear Filters
          </button>
        </>
      )}
    </div>
  );
}
