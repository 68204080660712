import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  errorHeading: {
    color: "red",
    fontWeight: "bold",
    marginLeft: "0px",
  },
  errorDescription: {
    color: "lightslategrey",
    marginLeft: "10px",
    fontWeight: "500",
  },
  result: {
    width: "98%",
    margin: "5rem auto",
    background: "#f8f4ea",
    paddingBottom: 20,
    marginBottom: 10,
    borderRadius: 15,
  },
  resultErrorDetailsRedeployment: {
    height: 180,
    width: "94%",
    background: "#ffffff",
    margin: "2em 0px 0px 0.2em",
    borderRadius: 25,
    padding: "2rem",
    fontSize: 15,

    display: "inline-block",
  },
  resultComponentHead: {
    marginTop: "15px",
    marginLeft: "5px",
    width: "100%",
  },
  webLinks: {
    color: "blue",
    marginLeft: "20px",
    fontSize: "0.9em",
    marginTop: "2px",
    marginRight: "20px",
  },
}));

const SearchMessage = ({ message, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.result}>
      <div className={classes.resultErrorDetailsRedeployment}>
        <Typography variant="h6" color="textSecondary">
          <div className={classes.resultComponentHead}>
            <p>
              <span className={classes.errorDescription}>{message}.</span>
            </p>
            <p>
              <span className={classes.webLinks}>{children}</span>
            </p>
          </div>
        </Typography>
      </div>
    </div>
  );
};

export default SearchMessage;
