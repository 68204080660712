import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

const useStyles = makeStyles((theme) => ({
  label: {
    background: "#FCD839",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: "0.30rem 0.6rem 0.30rem 0.6rem",
    fontSize: "0.65em",
    fontWeight: 600,
    color: "#000",
  },
}));

const ValidateSkills = () => {
  const classes = useStyles();

  const { validatedSkills, setValidatedSkills } = useCustomSearchParams();

  const handleChange = (e) => setValidatedSkills(e.target.checked);

  return (
    <>
      <Typography className={classes.label}>Validated Skills</Typography>
      <Checkbox checked={validatedSkills} onChange={handleChange} />
    </>
  );
};

export default ValidateSkills;
