const IT_FUNCTIONAL_SKILLS = [
  " Apple design system",
  "A-B testing",
  "Accessibility Audit (WCAG & ISO 9241)",
  "Accessibility Standard (WCAG & ISO 9241)",
  "Account Management",
  "Agile Business Productisation",
  "Agile Coaching",
  "Agile Delivery",
  "Agile Facilitation",
  "Agile Mentoring",
  "Agile Teaching",
  "API Definitions",
  "Application Instrumentation",
  "Audit Preparation",
  "Automation",
  "Benefits Analysis",
  "Benefits Management",
  "Beta Testing",
  "Big Data Technologies",
  "Brand Design System Creation",
  "Business Acumen",
  "Business adoption & value realisation",
  "Business Analysis Planning & monitoring",
  "Business Analysis Techniques",
  "Business Continuity",
  "Business Model Canvas",
  "Card Sorting",
  "Change impacting, mitigation, and embedding",
  "Change measurement",
  "Change network management",
  "Change strategy and planning",
  "Cloud Native Development",
  "Communication management",
  "Competency and knowledge management",
  "Competitor Analysis",
  "Compliance Monitoring and Assurance",
  "Computing Foundations",
  "Concept Testing",
  "Conducting Face to face interviews",
  "Contextual  inquiry",
  "Contract Scope Elicitation",
  "Control Design",
  "Control Operation",
  "CRM",
  "CSS Delivery - Oversight",
  "Culture Design & Behaviour Change",
  "Customer Experience Design",
  "Customer Journey Mapping",
  "Customer Relationship Management",
  "Data Analysis",
  "Data Control",
  "Data Engineering",
  "Data Governance and Security",
  "Data Governance Tools",
  "Data Lifecycle",
  "Data Mastership",
  "Data Modelling",
  "Data Science",
  "Data Visualisation",
  "Data visualization",
  "Data Warehousing",
  "Define IM Plan",
  "Define IM Strategy",
  "Design for Print",
  "Diary Studies",
  "Digital Accessibility",
  "Digital Design",
  "Discussion guides facilitation",
  "Document Control",
  "Elicitation & collaboration",
  "Empathy mapping",
  "Enterprise Agile Transformation",
  "Enterprise Recovery – Readiness Capabilities",
  "ERP - S/4 HANA Migration",
  "ERP - SAP Archiving",
  "ERP - SAP Asset Performance Management",
  "ERP - SAP Business Network for Asset Management",
  "ERP - SAP Dynamic Form",
  "ERP - SAP Field Service Management",
  "ERP - SAP Geographical Enablement Framework",
  "ERP - SAP S/4HANA- Business Partner",
  "ERP - SAP S/4HANA- FSCM Credit Management",
  "ERP - SAP S4HC  - CL METH",
  "ERP - SAP S4HC  - EXT",
  "ERP - SAP S4HC  - SECURITY",
  "ERP - SAP S4HC – AM/PM",
  "ERP - SAP S4HC – CP",
  "ERP - SAP S4HC – DATA",
  "ERP - SAP S4HC – PROJ MGT",
  "ERP - SAP S4HC – SC",
  "ERP - SAP S4HC–  PS",
  "ERP - SAP Service and Asset Manager (SSAM)",
  "ERP - SAP Upgrade",
  "Ethnographic Research",
  "Event Management AIOps",
  "Figma",
  "Focus groups research",
  "Google Material Design",
  "Guerrilla research",
  "Hand drawing",
  "Identity & Access Management",
  "Illustration Design",
  "IM Operational Processes",
  "IM Policy Development and Implementation",
  "IM Requirement Analysis and Specification",
  "Information & Data Lifecycle & Quality",
  "Information & Data Strategy & Governance",
  "Information & Data Value Exploitation",
  "Information Architecture",
  "Information Design",
  "Information Handover and Access",
  "Information Management Technology",
  "Information Protection Practices",
  "Inner Source",
  "Integration Architecture",
  "iOS Development",
  "IT Control Framework",
  "ITIL - Foundation",
  "ITIL - Service Design",
  "ITIL - Service Operations",
  "ITIL - Service Strategy",
  "ITIL - Service Transition",
  "ITIL 4 Strategic Leader",
  "Journey mapping",
  "Kepner-Tregoe",
  "Kotlin",
  "Lean Agile Practices",
  "Learning design, build, execution, and measurement",
  "Learning needs, strategy, and planning",
  "Legal & Regulatory",
  "Logo Design",
  "Longitudinal Studies",
  "Machine Learning and AI",
  "Metrics Analysis",
  "Mobile App Design",
  "Moderated and unmoderated remote testing",
  "Multi Variant testing",
  "Navigation Schemas",
  "Node.js",
  "Ontologies",
  "Optimisation - Conversion Design",
  "Organisation Design & Deployment",
  "Payment Card Industry - Data Security Standards",
  "Persona generation",
  "PowerShell",
  "Product Selection",
  "Product Vision & Strategy",
  "Product Discovery",
  "Product Leadership",
  "Product Lifecycle Management",
  "Product Measurements",
  "Product Prioritisation",
  "Programme Management",
  "Project Assurance & Compliance",
  "Project Governance",
  "Project Management",
  "Project Planning & Tracking",
  "Project Risk Assessment & Management",
  "Project Scoping & Budgeting",
  "Prototyping",
  "Prototyping - interactive",
  "Prototyping - Static",
  "Qualitative Research",
  "Quality Assurance & Reporting",
  "Quantitative Research",
  "Remote Usability Testing",
  "Requirement analysis & design definition",
  "Requirement Lifecycle management",
  "Research report creation",
  "Responsive web design",
  "Risk and Quality Management",
  "Risk Assessment and Management",
  "Role & Position design & deployment",
  "SaaS",
  "Saas Solution Delivery - Adoption Framework",
  "Script writing",
  "Security Architecture",
  "Segment Architecture",
  "SEO Search Engine Optimisation",
  "Service Design",
  "SOA Architecture",
  "Software Construction",
  "Software Design",
  "Software Engineering Economics",
  "Software Engineering Management",
  "Software Engineering Models and Methods",
  "Software Engineering Process",
  "Software Engineering Professional Practice",
  "Software Maintenance",
  "Software Quality",
  "Software Requirements",
  "Software Testing",
  "Solution Architecture",
  "Solution Evaluation",
  "Stakeholder Engagement & Communications for Change Practitioners",
  "Stakeholder management",
  "Strategy analysis",
  "Supplier Management",
  "Survey writing",
  "Taxonomies",
  "Technical Architecture",
  "Testing - Acceptance Test Driven Development/Behavior Driven Development",
  "Testing - Adobe solutions",
  "Testing - API Testing",
  "Testing - Data & Analytics",
  "Testing - Deployment Testing",
  "Testing - Functional Testing",
  "Testing - ITAS Testing",
  "Testing - Mobile Testing",
  "Testing - Payments and Certification Testing",
  "Testing - Retail Lab Test tool development",
  "Testing - Salesforce",
  "Testing - SAP - ECC \\ SAP S4 HANA",
  "Testing - Service Now",
  "Testing - Site Systems Testing",
  "Testing - Test Management",
  "Testing - Vulnerability",
  "Testing - Workday",
  "TOE (Target Operating Environment)",
  "TOGAF",
  "Training Management",
  "Transformation Management",
  "UI Bug Resolution",
  "UI Guidelines / Design System Creation",
  "UI Guidelines updates",
  "Unstructured Data Analytics",
  "Usability testing",
  "Usability Testing (Formal)",
  "Usability Testing (Informal)",
  "User insight acquisition",
  "UX Design",
  "Value  Proposition canvas",
  "Value Proposition Canvas",
  "Vendor Management",
  "WAN",
  "Waterfall Delivery",
  "Web Service",
  "Wireframing",
  "XML",
];
const IT_TECHNICAL_SKILLS = [
  "(D)DOS mitigation",
  ".Net Core",
  ".NET Framework up to 4.x (Legacy)",
  "Acquisition & Divestment IM Tools",
  "Active Directory (Azure)",
  "ADOBE AEM",
  "Adobe Analytics",
  "Adobe Audience Manager",
  "Adobe Campaign",
  "Adobe Creative Suite",
  "Adobe Experience Platform",
  "Adobe Target",
  "Agile Technical Craftsmanship",
  "AI Engineering essentials",
  "Alteryx",
 "Anaplan EPM / Planning",
  "Android Development",
  "Angular.js (Legacy)",
  "Ansible",
  "API Management",
  "Application Hosting",
  "Application Security",
  "ArchiMate",
  "ARM Templates (Azure)",
  "Artificial Intelligence",
  "ASP.NET (Legacy)",
  "AspenTech",
  "Aspera",
  "Aternity",
  "Audio Visual Conferencing",
  "Audio Visual HEC",
  "Audio Visual Monitoring",
  "AVEVA Suite",
  "AWS",
  "AWS Data Engineering",
  "Azure Advanced Analytics",
  "Azure AI Engineering",
  "AZURE ANALYSIS SERVICE",
  "Azure APP SERVICE",
  "Azure cloud",
  "Azure Cognitive Services",
  "Azure Data Engineering",
  "Azure Data Factory",
  "Azure Data Platform Engineering",
  "AZURE DATABRICKS",
  "Azure Datalake (Gen1/Gen2/Blob)",
  "Azure DevOps",
  "Azure DevOps & Data Ops",
  "AZURE FUNCTIONS",
  "Azure Graph",
  "Azure PaaS",
  "Azure Pipelines",
  "Azure Service Bus",
  "AZURE SQL DB",
  "AZURE SYNAPSE",
  "Bash",
  "Basic Predictive modelling",
  "BizzDesign",
  "Blockchain",
  "Bootstrap",
  "Business Process Modelling",
  "C",
  "C#",
  "C++",
  "Cloud Computing",
  "Cloud Integration",
  "Cloud security",
  "COBOL (Legacy)",
  "Collective",
  "Collibra",
  "CON - Field Telecoms",
  "CON - Network Protocols",
  "CON - Network Switching and Routing",
  "CON - Physical Network Infrastructure",
  "CON - Telecoms and Network Standards",
  "CON - Voice",
  "Confluent Kafka",
  "Containerisation",
  "Continuous Integration / Continuous Development Practices",
  "Control -M",
  "Cyber Defence Overview",
  "D3.js",
  "Data / Information Security",
  "Data Blending",
  "Data Integration",
  "Data Platforms",
  "Data science / statistics consultancy skills",
  "Data Virtualization",
  "Database Management and Design",
  "DataBricks",
  "Design Thinking",
  "DevOps",
  "Digital Forensics",
  "Digital Workspace",
  "Distributed Control Systems (DCS)",
  "Docker",
  "Document Database",
  "Documentum",
  "E-Discovery",
  "E-Spir",
  "EAI",
  "EDMS",
  "Electron",
  "Encryption",
  "End Point Security",
  "Endur",
  "Enterprise Client Technologies",
  "Enterprise Recovery - Tooling",
  "Entity Framework Core",
  "ERP - ABAP CDS",
  "ERP - HANA Smart Data Integration (SDI)",
  "ERP - HANA XS - SAP HANA views , XSOData",
  "ERP - HANA XS - XSJS",
  "ERP - OneSource Integration",
  "ERP - S/4 HANA",
  "ERP - S/4 HANA EWM (Extended Warehouse Management)",
  "ERP - S/4 HANA TM (Transportation Management)",
  "ERP - S/4 HANA-CTRM",
  "ERP - S/4 HANA-IBP",
  "ERP - S4HANA EWM (Extended Warehouse Management)",
  "ERP - S4HANA TM (Transportation Management)",
  "ERP - SAP",
  "ERP - SAP ABAP",
  "ERP - SAP ABAP Webdynpro",
  "ERP - SAP ABAP Workflow",
  "ERP - SAP Adobe Forms",
  "ERP - SAP ALE/EDI",
  "ERP - SAP AMDPs",
  "ERP - SAP API Management ",
  "ERP - SAP Ariba",
  "ERP - SAP Ariba Procurement Management",
  "ERP - SAP BASIS",
  "ERP - SAP BODS / SAP Data Services",
  "ERP - SAP BPC",
  "ERP - SAP BRF",
  "ERP - SAP Build Apps (LCNC)",
  "ERP - SAP Build Central WorkZone",
  "ERP - SAP Build Process Automation",
  "ERP - SAP BusinessObjects",
  "ERP - SAP Cloud Application Programming Model",
  "ERP - SAP Cloud Foundry",
  "ERP - SAP Cloud Integration",
  "ERP - SAP Cloud Integration DS",
  "ERP - SAP Cloud Platform",
  "ERP - SAP Cloud Platform Identity Authentication",
  "ERP - SAP Cloud Platform Identity Provisioning",
  "ERP - SAP Cloud Platform Integration (CPI)",
  "ERP - SAP Cloud Platform Mobile Services",
  "ERP - SAP CO",
  "ERP - SAP Concur Expense Management",
  "ERP - SAP CPL",
  "ERP - SAP CPS",
  "ERP - SAP CRM",
  "ERP - SAP CS",
  "ERP - SAP DMS",
  "ERP - SAP EC",
  "ERP - SAP EHS",
  "ERP - SAP EIM",
  "ERP - SAP EP - Admin",
  "ERP - SAP ERP",
  "ERP - SAP Event Mesh/Adavanced Event Mesh",
  "ERP - SAP FCC",
  "ERP - SAP FI",
  "ERP - SAP FIORI",
  "ERP - SAP Focused build",
  "ERP - SAP FSCM",
  "ERP - SAP Graphs",
  "ERP - SAP GRC",
  "ERP - SAP GTS",
  "ERP - SAP HANA",
  "ERP - SAP HR",
  "ERP - SAP ILM",
  "ERP - SAP Integration Content Advisor",
  "ERP - SAP IS-OIL-Downstream",
  "ERP - SAP IS-OIL-Downstream-HM",
  "ERP - SAP IS-OIL-Downstream-TD",
  "ERP - SAP IS-OIL-Downstream-TSW",
  "ERP - SAP JVA",
  "ERP - SAP LE",
  "ERP - SAP LO - General",
  "ERP - SAP Lumira",
  "ERP - SAP MDG",
  "ERP - SAP MDG - Ariba Integration",
  "ERP - SAP MDG - Consolidation",
  "ERP - SAP MDG - EAM",
  "ERP - SAP MII",
  "ERP - SAP ML",
  "ERP - SAP MM",
  "ERP - SAP Mobile Development Kit (MDK)",
  "ERP - SAP MRO",
  "ERP - SAP Odata",
  "ERP - SAP PCM",
  "ERP - SAP PI",
  "ERP - SAP PLM",
  "ERP - SAP PM",
  "ERP - SAP PowerBuilder",
  "ERP - SAP PP",
  "ERP - SAP PPM",
  "ERP - SAP PRA",
  "ERP - SAP PS",
  "ERP - SAP QM",
  "ERP - SAP RE",
  "ERP - SAP S/4HANA Sales",
  "ERP - SAP S/4 Central Finance",
  "ERP - SAP S4HC – FI",
  "ERP - SAP SCP WORKFLOW",
  "ERP - SAP SD",
  "ERP - SAP Security",
  "ERP - SAP SLT",
  "ERP - SAP Solution Manager",
  "ERP - SAP TR",
  "ERP - SAP Transport Management",
  "ERP - SAP UI5",
  "ERP - SAP WM",
  "ERP - SAP AA",
  "ERP - SAP Hybris eBusiness Suite - C/4HANA",
  "ERP- SAP S/4HANA Product Compliance",
  "ERP-Fin-Concur Travel and Expense",
  "ERP-Fin-S/4-Accounts Payable (FI-AP)",
  "ERP-Fin-S/4-Accounts Receivable (FI-AR)",
  "ERP-Fin-S/4-Asset Accounting (FI-AA)",
  "ERP-Fin-S/4-Bank Accounting (FI-BL)",
  "ERP-Fin-S/4-Cash and Liquidity Management",
  "ERP-Fin-S/4-Contract and Lease Management",
  "ERP-Fin-S/4-Controlling (CO)",
  "ERP-Fin-S/4-Credit and Collections Management",
  "ERP-Fin-S/4-Enterprise Project & Portfolio Management",
  "ERP-Fin-S/4-General Ledger Accounting (FI-GL)",
  "ERP-Fin-S/4-Group Reporting",
  "ERP-Fin-S/4-Invoice Management",
  "ERP-Fin-S/4-Joint Venture Accounting (JVA)",
  "ERP-Fin-S/4-Payments and Bank Communications",
  "ERP-Fin-S/4-Project System (PS)",
  "ERP-Fin-S/4-Receivables Management",
  "ERP-Fin-S/4-SAP Cash Application",
  "ERP-Fin-S/4-SAP Digital Payments Add-on",
  "ERP-Fin-S/4-SAP Document and Reporting Compliance",
  "ERP-Fin-S/4-SAP Multi-Bank Connectivity",
  "ERP-Fin-S/4-SAP Oil & Gas (PRA - Production and Revenue Accounting)",
  "ERP-Fin-S/4-SAP Profitability and Performance Management",
  "ERP-Fin-S/4-SAP S/4HANA Cloud for advanced financial closing",
  "ERP-Fin-S/4-Settlement Management",
  "ERP-Fin-S/4-Tax Accounting",
  "ERP-Fin-S/4-Treasury and Risk Management",
  "ETL",
  "Exchange Online",
  "Exploratory analysis",
  "File Mover",
  "FitNesse",
  "Fortran (Legacy)",
  "Git",
  "GitHub",
  "Go",
  "Graph Database",
  "GraphQL",
  "gRPC",
  "GUI Design",
  "Heuristic evaluation",
  "Hexagon Suite",
  "HTML (deprecated)",
  "HTML5",
  "Informatica ActiveVos Workflow (AVOS)",
  "Informatica Data Quality (IDQ)",
  "Informatica Master Data Management (MDM)",
  "Infrastructure",
  "Infrastructure Enterprise Recovery",
  "Internet of Things",
  "InVision",
  "iPaaS - MuleSoft",
  "Java",
  "Java – External Call & User Exit for Informatica MDM",
  "Javascript",
  "jQuery",
  "JSON",
  "K2",
  "Kubernetes",
  "Linux, Python",
  "Logic Apps",
  "Machine Learning",
  "Machine Learning & Statistics foundations",
  "Machine Learning in Azure, AWS",
  "Mainframe Technologies (JCL/DB2/VSAM)",
  "Master Data Management",
  "MatLab",
  "Metaframeworks",
  "Microsoft Azure",
  "Microsoft Teams",
  "Middleware",
  "Mobile",
  "Mobile security",
  "Model Based SE",
  "Moogsoft AIOps",
  "MS Biztalk",
  "MS Office 365",
  "MS Project",
  "MS SQL",
  "MS SSIS",
  "MS VB Script (deprecated)",
  "MS VBA (deprecated)",
  "MXNet",
  "MySql",
  "Natural Language Processing",
  "Network Security",
  "No SQL",
  "Node JS",
  "NoSQL",
  "OLAP - Online Analytical Processing",
  "OneDrive for Business",
  "Open Source Intelligence (OSINT)",
  "Open Text",
  "Openlink Endur ",
  "Oracle Database Administration",
  "OSIsoft PI -PI Clients",
  "OSIsoft PI -PI Data Integration and Development",
  "OSIsoft PI -PI Server and related Applications",
  "OSIsoft PI -RT Analytics",
  "OSIsoft PI Server Administration",
  "OSIsoft PI-PI Interfaces",
  "P&ID",
  "Participant selection",
  "PDMS",
  "PEGA (legacy)",
  "Penetration Testing",
  "PHP (Legacy)",
  "PL/SQL",
  "Power Apps",
  "Power Platform",
  "PowerBI",
  "PowerDesigner",
  "Process Control Domain (PCD)",
  "Project Resourcing Management",
  "Proto.io",
  "Public Key Infrastructure",
  "Python",
  "React JS",
  "React Native",
  "RESTful APIs / ODATA",
  "Robotics",
  "Robotics Process Automation",
  "Rust (programming language)",
  "S/4H Analytics (embedded reporting)",
  "SaaS in MSU Context",
  "Salesforce Analytics Cloud",
  "Salesforce Apex",
  "Salesforce Community Cloud",
  "Salesforce Data Management",
  "Salesforce Einstein Analytics",
  "Salesforce Industries/ Vlocity",
  "Salesforce Integration Cloud",
  "Salesforce Lightning Web Components",
  "Salesforce Marketing Cloud",
  "Salesforce Service Cloud",
  "Salesforce System Configuration",
 "Salesforce Sales Cloud",
  "SAP Adobe Service(Cloud )",
  "SAP Analytics Cloud",
  "SAP Asset Manager & Asset Intelligence Network",
  "SAP BI",
  "SAP BOFC (or SAP BFC)",
  "SAP BTP Extension suite",
  "SAP BTP Integration Suite",
  "SAP BW",
  "SAP Commerce (Hybris)",
  "SAP Commerce (Spartacus)",
  "SAP Customer Data Cloud",
  "SAP Data Hub",
  "SAP Fieldglass",
  "SAP/PM",
  "SAS",
  "Secure Development",
  "Secure Operations Management",
  "Security (Technical) Data Management",
  "Security Analytics",
  "Security Incident Analysis",
  "Security Incident Management",
  "Security Incident Monitoring",
  "Security Orchestration Automation and Response (SOAR)",
  "Selenium Webdriver",
  "Serverless computing",
  "ServiceNow",
  "ServiceNow ITSM",
  "ServiceNow Platform Engineering",
  "ServiceNow Platform Implementation",
  "SharePoint",
  "Sharepoint Online",
  "Shell PetroSigns / GeoSigns / WellVantage",
  "Shell ProjectVantage",
  "Sketch",
  "Skype for Business",
  "Software Configuration Management",
  "Spark",
  "Specialist AI Engineering",
  "Specialist Machine learning",
  "Specialist Statistical modelling",
  "SPEL",
  "SPFx framework",
  "SPI",
  "Splunk",
  "Spotfire",
  "Spring",
  "SQL Server Analysis Services",
  "Storage Management and Design",
  "Storytelling with data & Data Visualization",
  "StreamSets",
  "Structured Query Language (SQL)",
  "Swift",
  "Team Foundation Version Control - TFVC (Legacy)",
  "Technical IM",
  "Telecoms networks and Equipment",
  "Tensorflow",
  "TerraForm",
  "Testing - Automation - Analytics",
  "Testing - Automation - FitNesse",
  "Testing - Automation - Protractor",
  "Testing - Automation - Selenium",
  "Testing - Automation - Service Now ATF",
  "Testing - Automation - UFT",
  "Testing - Automation - Worksoft Certify",
  "Testing - Mobility Automation - Appium",
  "Testing - Performance Center",
  "Testing - Performance Testing  - J-Meter",
  "Testing – Informatica Test Data Management – Data Anonymization",
  "Text Analytics",
  "Threat Intelligence & Management",
  "Tieto Energy Components",
  "TypeScript",
  "Unix Shell Scripting",
  "Unstructured Data Migration & Metadata Enrichment",
  "Virtual/Augmented Reality",
  "Virtualization",
  "Visual Basic (legacy)",
  "Visual Design",
  "Visual Studio",
  "Visual Studio Code",
  "Vulnerability Assessment",
  "Vulnerability Management",
  "Windows 10 Administration",
  "Windows Communication Foundation",
  "Windows Forms (legacy)",
  "Windows Server Administration",
  "Workday HCM",
  "Xamarin (legacy)",
  "XSLT",
  "Zeplin",
];
const DOMAIN_KNOWLEDGE = [
  "Acquisitions",
  "Chemicals & Products - Chems - X-Process",
  "Chemicals & Products - Growth, Transform & Data",
  "Chemicals & Products - Margin Optimization",
  "Chemicals & Products - Marketing, CX & Commercial",
  "Chemicals & Products - Pennsylvania Chemicals",
  "Chemicals & Products - Supply Chain & Logistics",
  "Chemicals & Products -Chemicals",
  "Chemicals & Products -ERP & X-Business",
  "Deliver Capital Projects",
  "Divestments",
  "Ensure Quality Products",
  "Enterprise Performance Management & Reserves - Planning & appraisal",
  "Enterprise Performance Management & Reserves - Reserves Assurance & Reporting",
  "Enterprise Performance Management & Reserves - Strategy & Portfolio",
  "Finance & Support Services",
  "Forecast and Plan Production",
  "GF - Corporate IT - Aircraft IT",
  "GF - Corporate IT - Corporate & External Relations - DEX",
  "GF - Corporate IT - Corporate Security",
  "GF - Corporate IT - External Relations IT",
  "GF - Corporate IT - Health IT",
  "GF - Corporate IT - Internal Communications IT",
  "GF - Corporate IT -Travel IT",
  "GF - Finance IT -  Hydroc. Inv. Reserves Acc. Market Risk IT",
  "GF - Finance IT - Accounts Payable",
  "GF - Finance IT - Credit Risk",
  "GF - Finance IT - Ebanking",
  "GF - Finance IT - Expenditure IT",
  "GF - Finance IT - Group Pensions",
  "GF - Finance IT - Group Planning",
  "GF - Finance IT - Group Reporting",
  "GF - Finance IT - Group Strategy & Portfolio",
  "GF - Finance IT - Insurance",
  "GF - Finance IT - Pensions and Payroll Accounting",
  "GF - Finance IT - Retailer Billing",
  "GF - Finance IT - Tax IT",
  "GF - Finance IT - Trading settlements",
  "GF - Finance IT - Treasury",
  "GF - HR Digital Core IT",
  "GF - HR Services IT",
  "GF - Legal IT",
  "HSSE & Reputation & Social Performance",
  "Information Risk Mgmt Foundation Basics",
  "IT - Sourcing & Vendor",
  "IT Risk Prioritisation & Identification",
  "Lubricants - Data & Analytics",
  "Lubricants - GC B2B marketing",
  "Lubricants - Global Lubricants Product & Quality",
  "Lubricants - Global Lubricants Supply Chain",
  "Lubricants - Global Lubricants Tech Services",
  "Lubricants - HSSE & Audit Global",
  "Lubricants - Key Accounts",
  "Lubricants - Logistics, Trade & Compliance",
  "Lubricants - Lubricants",
  "Lubricants - Planning & Scheduling",
  "Lubricants - Production life cycle",
  "Lubricants - Sales Pipeline",
  "Maintenance Reliablity Turnaround & Frontline Mobility - Manage Threats and Opportunities",
  "Maintenance Reliability Turnaround & Frontline Mobility – Connected Worker",
  "Maintenance Reliability Turnaround & Frontline Mobility – Maintain Equipment Care",
  "Maintenance Reliability Turnaround & Frontline Mobility – Maintain Execution",
  "Maintenance Reliability Turnaround & Frontline Mobility – Management of Change",
  "Maintenance Reliability Turnaround & Frontline Mobility – Turnaround",
  "MB -  Global Marketing - Connected Customer",
  "MB - Fleet Solutions",
  "MB - FS - Commercial Road Transport",
  "MB - FS - Payment and Settlement (B2C Payment\n,B2B Payment, Settlement)",
  "MB - Global Marketing- B2C",
  "MB - Global Marketing- Connected Customer",
  "MB - Global Marketing- Data Analytics",
  "MB - Global Marketing- Digital Channels",
  "MB - Global Marketing- Digital Loyalty",
  "MB - Global Marketing- Digital Pay",
  "MB - Global Marketing- Innovation",
  "MB - Global Marketing- Media & Campaign",
  "MB - Global Marketing- Mobility Products - NFR",
  "MB - Global Marketing-Mobility Products Fuels Pricing",
  "MB - Global Marketing-Retailing and Operations",
  "MB - Global Network-Real Estate-Engineering\n-Planning",
  "MB - Retail Americas",
  "MB - Retail East",
  "MB - Retail Europe & South Africa",
  "MB - Retail- Site Systems",
  "MB - Security",
  "MB - STRONG",
  "NBD - Develop Exploration Business",
  "NBD - Develop New Business",
  "Power Markets",
"Produce Hydrocarbon – Ensure Safe Production",
"Produce Hydrocarbon – Forecast & Plant Production",
"Produce Hydrocarbon – Green House Gas",
"Produce Hydrocarbon – Permit to Work",
"Produce Hydrocarbon – Proactive Technical Monitoring",
"Produce Hydrocarbon – Produce Hydrocarbon & Energy Accounting",
  "PT - Deliver Capital Projects",
  "R & ES  - Asset Management",
  "R & ES  - eMobility",
  "R & ES  - Hydrogen",
  "R & ES  - Nature Base Solution",
  "R & ES  - Power Digital Core",
  "R & ES - Global Customer Solutions",
  "RE - Real Estate",
  "Renewables & Energy Solutions",
  "Sectors & Decarbonisation - Agriculture & Forestry",
  "SSW-Geology",
  "SSW-Geomechanics",
  "SSW-Geophysics",
  "SSW-Petroleum Geology",
  "SSW-Petrophysics",
  "SSW-Production Engineering",
  "SSW-Reservoir Engineering and Modeling",
  "SSW-Seismic Data Acquisition",
  "SSW-Seismic data Interpretation",
  "SSW-Seismic Data Processing",
  "T&S - Cross Commodity - 3 LoD Model",
  "T&S - Cross Commodity - Accounting & Financial Reporting",
  "T&S - Cross Commodity - Accounts Receivables (AR)",
  "T&S - Cross Commodity - Accounts Settlements (AP)",
  "T&S - Cross Commodity - Assurance",
  "T&S - Cross Commodity - Basics of Accounting Principles",
  "T&S - Cross Commodity - Basics of TSA",
  "T&S - Cross Commodity - Business Functions Understanding",
  "T&S - Cross Commodity - CLCM & Sanctions",
  "T&S - Cross Commodity - Commodity Market & Risk",
  "T&S - Cross Commodity - Commodity Trading Regulation",
  "T&S - Cross Commodity - Compliance Framework Fundamentals",
  "T&S - Cross Commodity - Confirmations process",
  "T&S - Cross Commodity - Contract Document Management",
  "T&S - Cross Commodity - Control Management",
  "T&S - Cross Commodity - Counterparty Mapping with ETRM Systems",
  "T&S - Cross Commodity - Counterparty Onboarding Process",
  "T&S - Cross Commodity - Counterparty Risk Management",
  "T&S - Cross Commodity - Credit Analysis",
  "T&S - Cross Commodity - Credit Risk Factors",
  "T&S - Cross Commodity - Deal Structuring",
  "T&S - Cross Commodity - Debt Chasing Process",
  "T&S - Cross Commodity - Domain Basics (HM/ERA)",
  "T&S - Cross Commodity - Electronic Confirmation (Equias)",
  "T&S - Cross Commodity - Familiarity with ESMA and FCA regulators",
  "T&S - Cross Commodity - Financial Accounting",
  "T&S - Cross Commodity - Financial Basics",
  "T&S - Cross Commodity - Financial Crime (FC) Compliance Application",
  "T&S - Cross Commodity - Financial Crime (FC) Regulation",
  "T&S - Cross Commodity - Forward Curve Modelling",
  "T&S - Cross Commodity - Framework",
  "T&S - Cross Commodity - Global Regulations and Regulators",
  "T&S - Cross Commodity - International Tax Policy",
  "T&S - Cross Commodity - Issue Management",
  "T&S - Cross Commodity - Journal entries wrt. SAP/ GL",
  "T&S - Cross Commodity - KYC Process",
  "T&S - Cross Commodity - Line of Defence / Finance Control Framework",
  "T&S - Cross Commodity - LoB specific variations of LoD",
  "T&S - Cross Commodity - Manage Direct Tax",
  "T&S - Cross Commodity - Manage Indirect Tax",
  "T&S - Cross Commodity - Market Risk Analytics",
  "T&S - Cross Commodity - Monitoring and Surveillance",
  "T&S - Cross Commodity - Netting Process",
  "T&S - Cross Commodity - North American Regulatory Reporting",
  "T&S - Cross Commodity - Options & Asset Valuation",
  "T&S - Cross Commodity - PAR Process Knowledge",
  "T&S - Cross Commodity - Payment Processing",
  "T&S - Cross Commodity - Regulatory Knowledge",
  "T&S - Cross Commodity - Requirements Gathering",
  "T&S - Cross Commodity - Risk Assessment",
  "T&S - Cross Commodity - Risk Metrics",
  "T&S - Cross Commodity - Risk-based decision making",
  "T&S - Cross Commodity - Sanctions & Embargoes Knowledge",
  "T&S - Cross Commodity - T&S Business and Market Fundamentals",
  "T&S - Cross Commodity - Tax Compliance, Regulations & Tax Administration",
  "T&S - Cross Commodity - TB2F Accounting Process",
  "T&S - Cross Commodity - Trade Repositories",
  "T&S - Cross Commodity - Trading Tax Basics",
  "T&S - Cross Commodity - Understand Integrated Process Risk Controls (IPRC) role in T&S",
  "T&S - Cross Commodity - Understanding of LOD1 and LOD2 functions",
  "T&S - Cross Commodity - Workflow Management",
  "T&S - Crude & Products - Bulk & Lease Trading",
  "T&S - Crude & Products - Chemical Feedstocks, Chemical Products & NGLs",
  "T&S - Crude & Products - Crude Business at Shell",
  "T&S - Crude & Products - Crude Freight",
  "T&S - Crude & Products - Crude Oil",
  "T&S - Crude & Products - Crude Pricing",
  "T&S - Crude & Products - Crude Purchase Agreements",
  "T&S - Crude & Products - Hydrocarbon Management",
  "T&S - Crude & Products - Inventory",
  "T&S - Crude & Products - Low Carbon Fuels",
  "T&S - Crude & Products - Mode of Transport",
  "T&S - Crude & Products - North America (NA)",
  "T&S - Crude & Products - Price Risk Management",
  "T&S - Crude & Products - Product Commodities",
  "T&S - Crude & Products - Product Purchase Agreements",
  "T&S - Crude & Products - Products Business at Shell",
  "T&S - Crude & Products - Sanctions",
  "T&S - Crude & Products - Storage",
  "T&S - Crude & Products - Supply & Commercial Fuels",
  "T&S - Crude & Products - World Outside of North America (WONA)",
  "T&S - ETRM, Commodity trading, Sales & Marketing, Commodity risk management",
  "T&S - Logistics - Asset Management",
  "T&S - Logistics - Commercial",
  "T&S - Logistics - Data Platform",
  "T&S - Logistics - Emissions",
  "T&S - Logistics - Energy Logistics",
  "T&S - Logistics - Environment Management",
  "T&S - Logistics - Fleet Management",
  "T&S - Logistics - Hauler Sourcing Management",
  "T&S - Logistics - HSSE",
  "T&S - Logistics - Maritime Assurance",
  "T&S - Logistics - Mobility Operations",
  "T&S - Logistics - On site operations (Terminal)",
  "T&S - Logistics - Operations & Process Automation",
  "T&S - Logistics - Order Fulfilment",
  "T&S - Logistics - Order to Cash Management",
  "T&S - Logistics - Overview of Road Transport",
  "T&S - Logistics - Process Safety & Cyber Security",
  "T&S - Logistics - Regulatory Compliance",
  "T&S - Logistics - S/4 Hana ERP Management",
  "T&S - Logistics - Terminal Maintenance & Operations",
  "T&S - Logistics - Terminal Operations before hauler arrives",
  "T&S - Products Trading",
  "T&S - Shell Energy - Algo",
  "T&S - Shell Energy - Asset Management & Optimisation",
  "T&S - Shell Energy - Basics of LNG",
  "T&S - Shell Energy - Broker Platform & Exchanges",
  "T&S - Shell Energy - Carbon Trading foundations",
  "T&S - Shell Energy - Characteristics of Natural Gas",
  "T&S - Shell Energy - Climate Strategy",
  "T&S - Shell Energy - Connecting Customer",
  "T&S - Shell Energy - Digitalisation",
  "T&S - Shell Energy - Endur",
  "T&S - Shell Energy - EPT Pricing Models",
  "T&S - Shell Energy - Gas Front Office Pricing",
  "T&S - Shell Energy - Gas Infrastructure",
  "T&S - Shell Energy - Gas Pricing Models",
  "T&S - Shell Energy - Gas Purchase agreements",
  "T&S - Shell Energy - Gas Scheduling",
  "T&S - Shell Energy - Glass Design",
  "T&S - Shell Energy - LNG Storage",
  "T&S - Shell Energy - Offtake Agreements",
  "T&S - Shell Energy - Power Consumer & Prosumer",
  "T&S - Shell Energy - Power Cross-Border Capacity",
  "T&S - Shell Energy - Power Generation",
  "T&S - Shell Energy - Power Pricing Models",
  "T&S - Shell Energy - Power Purchase agreements",
  "T&S - Shell Energy - Power Scheduling",
  "T&S - Shell Energy - Power Storage",
  "T&S - Shell Energy - Pre-deal approval process",
  "T&S - Shell Energy - Pricing (PDS & PCB)",
  "T&S - Shell Energy - QuADS",
  "T&S - Shell Energy - Renewable energy initiatives",
  "T&S - Shell Energy - Shell Energy EPT Desks & Operations",
  "T&S - Shell Energy - Shell Energy Gas Desks & Operations",
  "T&S - Shell Energy - Shell Energy Power Desks & Operations",
  "T&S - Shell Energy - Shell Energy SLMT Desks & Operations",
  "T&S - Shell Energy - SLMT Commercial operations",
  "T&S - Shell Energy - SLMT Pricing models",
  "T&S - Shell Energy - Trade Automation",
  "T&S - Shell Energy - Value Chain Optimization (VCO)",
  "T&S - Trading Foundations - Carbon foundations",
  "T&S - Trading Foundations - Contract Negotiation",
  "T&S - Trading Foundations - Deal Origination",
  "T&S - Trading Foundations - Delivery, Logistics & Transport",
  "T&S - Trading Foundations - Energy Markets",
  "T&S - Trading Foundations - ETRM Systems",
  "T&S - Trading Foundations - Market Analysis",
  "T&S - Trading Foundations - Post-Trade Analysis",
  "T&S - Trading Foundations - Risk management",
  "T&S - Trading Foundations - Settlement",
  "T&S - Trading Foundations - Shell Commodities",
  "T&S - Trading Foundations - Supply & Demand",
  "T&S - Trading Foundations - Trade Execution",
  "T&S - Trading Foundations - Trading Methods & Types",
  "T&S - Trading Foundations - Trading Products",
  "T&S -LNG trading, ETRM , Gas Trading, Power Trading",
  "UP - Managed Supply Chain - Integrated Activity Planning",
  "UP -Managed Supply Chain - People Movement",
  "UP -Managed Supply Chain - Procure Goods & Services & MM",
  "UP -Managed Supply Chain - Waste Management",
  "UP -Well Reservoir Facility Management",
];
const BUSINESS_AND_PROFESSIONAL_EFFECTIVENESS = [
  "Active Listening",
  "Business Intimacy",
  "Coaching & Mentoring",
  "Commerciality",
  "Communications Effectiveness",
  "Consultancy",
  "Continuous Improvement",
  "Critical thinking",
  "Data Access",
  "Data Catalogue",
  "Data Culture",
  "Data Excellence",
  "Data Governance",
  "Data Management Capabilities",
  "Data Quality Management",
  "Data Strategy",
  "Data Tooling",
  "Decision Making",
  "Diversity & Inclusion",
  "Emotional Intelligence",
  "Engage & Inspire",
  "Entrepreneurial skills",
  "Externalisation",
  "Facilitation skills",
  "Financial skills",
  "Influencing",
  "Innovation",
  "Interpersonal Skills",
  "Knowledge management",
  "Managing and Developing Others",
  "Negotiation",
  "Networking with people",
  "Performance Management",
  "Personal and Professional Development",
  "Presentation skills",
  "Problem solving",
  "Self Awareness",
  "Stewardship",
  "Strategic Analysis",
  "Team Building",
  "Thought Leadership (Technology)",
  "Time Management",
];
const IT_SKILLS_CERTIFICATION = [
  "Adobe Analytics Business Practioner Certification",
  "Adobe Analytics Developer",
  "Adobe Campaign Classic Business Practitioner Certification",
  "Adobe Campaign Classic Developer",
  "Adobe Campaign Standard Developer",
  "Adobe Experience Manager Sites Developer",
  "Adobe Experience Manager Sites Lead Developer",
  "Adobe Experience Platform Qualified Certification",
  "Adobe Target Business Practitioner Certification",
  "Advanced Certified Scrum Master (A-CSM)",
  "Advanced Certified Scrum Product Owner (A-CSPO)",
  "Agile Certified Practitioner (PMI-ACP)",
  "Agile Coaching: Agile Team Facilitation (ICP-ATF)",
  "Agile Coaching: Coaching (ICP-ACC)",
  "Agile Fundamentals (ICP)",
  "Agile Product Management",
  "Agile Product Ownership (ICP-APO)",
  "Agile Project Management Foundation",
  "Agile Software Engineering",
  "Agility in the Enterprise (ICP-ENT)",
  "AgilityHealth Facilitator (AHF)",
  "AI-100: Designing and Implementing an Azure AI Solution",
  "AI-102: Designing and Implementing a Microsoft Azure AI Solution",
  "AI-900: Microsoft Azure AI Fundamentals",
  "Alteryx Product Certification Program",
  "Alteryx Product Certification Program- Designer Advanced",
  "Alteryx Product Certification Program- Designer Core",
  "Alteryx Product Certification Program- Designer Expert",
  "Anaplan Certified Model Builder",
  "Anaplan Model Builder Level 1",
  "Anaplan Model Builder Level 2",
  "Anaplan Model Builder Level 3",
  "Applying Professional Scrum (APS)",
  "Architectural Thinking",
  "AWS Associate: SysOps Administrator",
  "AWS Certified Cloud Practitioner",
  "AWS Certified Developer - Associate",
  "AWS Certified DevOps Engineer",
"AWS Certified Security – Specialty",
"AWS Certified Solutions Architect – Associate Level",
"AWS Foundational: Certified Cloud Practitioner",
"AWS Professional: DevOps Engineer",
"AZ-102: Microsoft Azure Administrator Certification",
"AZ-103: Microsoft Azure Administrator Certification",
"AZ-203: Developing Solutions for Microsoft Azure",
"AZ-220: Microsoft Azure IoT Developer",
"AZ-301: Microsoft Azure Architect Design",
"AZ-303: Microsoft Azure Architect Technologies",
"AZ-304: Microsoft Azure Architect Design",
"AZ-400: Microsoft Azure Devops Solutions",
"AZ-500: Microsoft Azure Security Technologies",
"AZ-900: Microsoft Azure Fundamentals",
"Big Data Engineer",
"Blue Team Fundamentals: Security Operations and Analysis",
"Building PI System Assets and analytics with PI AF",
"C++ Certified Professional Programmer",
"CBAP – Certified Business Analysis Professional",
"CBDA - Certification in Business Data Analytics (IIBA®- CBDA)",
"CC – Certified in Cybersecurity",
"CCA Spark and Hadoop Developer Exam (CCA175)",
"CCBA – Certification of Capability in Business Analysis",
"CCP Data Engineer Exam (DE575)",
"Certificate of the Business Continuity Institute (CBCI)",
"Certified Agile Leadership Essentials (CAL-E)",
"Certified Agile Leadership for Organizations(CAL-O)",
"Certified Agile Leadership for Teams(CAL-T)",
"Certified Agile Leadership II (CAL-II)",
"Certified Analytics Professional (CAP)",
"Certified Associate in Python Programming (PCAP)",
"Certified Azure DevOps Engineer",
"Certified Azure Solutions Architect",
"Certified Blockchain Developer",
"Certified Cloud Security Professional (CCSP)",
"Certified Data Management Professional (CDMP)",
"Certified E-Discovery Specialist (CEDS™)",
  "Certified Enterprise Coach (CEC)",
  "Certified Ethical Hacker",
  "Certified in Risk and Information Systems Control (CRISC)",
  "Certified Information Privacy Manager (CIPM)",
  "Certified Information Privacy Professional (CIPP)",
  "Certified Information Privacy Technology (CIPT)",
  "Certified Information Professional (CIP)",
  "Certified Information Security Manager (CISM)",
  "Certified Information System Security Professional (CISSP)",
  "Certified Information Systems Auditor (CISA)",
  "Certified Information Systems Security Professional (CISSP)",
  "Certified Internal Auditor (CIA)",
  "Certified Kubernetes Administrator (CKA)",
  "Certified Kubernetes Application Developer (CKAD)",
  "Certified LeSS for Executives",
  "Certified LeSS Practitioner",
"Certified NoSQL Analyst (CAN) – MongoDB",
  "Certified R Programmer for Analytics",
  "Certified Risk Management Professional (CRMP)",
  "Certified Scrum Master (CSM)",
  "Certified Scrum Product Owner (CSPO)",
  "Certified Scrum Professional - Product Owner(CSP-PO)",
  "Certified Scrum Professional - ScrumMaster (CSP-SM)",
  "Certified Scrum Trainer (CST)",
  "Certified Software Asset Management (CSAM)",
  "Certified Solutions Architect",
  "Certified Team Coach (CTC)",
  "Change Management Practitioner (APMG International) Certification",
  "Cisco Certified Internetwork Expert (CCIE )",
  "Cisco Certified Network Associate (CCNA)",
  "Cisco Certified Network Professional (CCNP)",
  "Cisco Certified Network Professional Service Provider (CCNP Service Provider)",
  "Cisco Certified Specialist - Enterprise Advanced Infrastructure Implementation",
  "Cisco Certified Specialist - Enterprise Core",
  "Cisco Certified Specialist - Enterprise Design",
  "Cisco Certified Specialist - Service Provider Advanced Routing Implementation",
  "Cisco Certified Specialist - Service Provider Core",
  "Cisco Certified Specialist - Service Provider VPN Services Implementation",
  "CISSP: Information Systems Security Architecture Professional (ISSAP)",
  "CISSP: Information Systems Security Engineering Professional (ISSEP)",
  "CISSP: Information Systems Security Management Professional (ISSMP)",
  "Cloud Certified - Professional Data Engineer",
  "Coaching Agile Transitions (ICP-CAT)",
  "COBIT 5 (Implementor)",
  "COBIT® 5 Foundation",
  "Collibra Business Analyst / Data Scientist",
  "Collibra Data Governance Lead",
  "Collibra Data Steward",
  "Collibra Foundation of Data Governance",
  "Collibra Getting Started",
  "Collibra Integration Engineer",
  "Collibra Platform Administrator",
  "Collibra Solution Architect",
  "Collibra Workflow Engineer",
  "CompTIA Security+",
  "Computer Hacking Forensic Investigator",
  "Construx Developer Boot Camp",
  "CPOA - Certificate in Product Ownership Analysis  (IIBA\u00ae-CPOA)",
  "Creating Basic Reports with PI data link",
  "CSSLP \u0096 Certified Secure Software Lifecycle Professional",
  "DA-100: Analyzing Data with Microsoft Power BI",
  "Data Analytics Foundation Certification",
  "Data Bricks",
  "Data Engineering Foundation Certification",
  "Data Science Certification using R",
  "Databricks Certified Data Engineer Professional certification",
  "Deep Learning with Tensorflow",
  "Denodo Platform 7.0 certified Administrator",
  "Denodo Platform 7.0 certified Architect",
  "Denodo Platform 7.0 certified Developer",
  "Designing and Implementing Big Data  Analytics Solution Exam 70 - 475",
  "Designing and Implementing Microsoft DevOps Solutions (AZ-400)",
  "DevOps Leader (DOL)",
  "Docker Certified Associate (DCA)",
  "DP-100: Designing and Implementing a Data Science Solution on Azure",
  "DP-200: Implementing an Azure Data Solution",
  "DP-201: Designing an Azure Data Solution",
  "DP-203: Data Engineering on Microsoft Azure",
  "DP-900: Microsoft Azure Data Fundamentals",
  "Dynatrace Associate",
"ECBA – Entry Certificate in Business Analysis",
  "Enterprise Product Ownership (ICE-EPO)",
  "ERP - OneSource Determination (New UI) Certified Professional User",
  "ERP - OneSource Determination Enterprise Cloud Certified Implementer",
  "ERP - OneSource Determination Original Cloud Certified Expert",
  "ERP - OneSource Determination Original Cloud Certified Professional User",
  "ERP - OneSource Determination SAP Global Next Certified Professional User",
  "ERP - S/4 HANA Cloud Certification",
  "ERP - SAP Certified Application Associate - Ariba Integration",
  "ERP - SAP Certified Application Associate - Central Finance in SAP S/4HANA",
  "ERP - SAP Certified Application Associate - SAP Analytics Cloud",
  "ERP - SAP Certified Application Associate - SAP Ariba Catalogs",
  "ERP - SAP Certified Application Associate - SAP Ariba Contracts",
  "ERP - SAP Certified Application Associate - SAP Ariba Procurement",
  "ERP - SAP Certified Application Associate - SAP Ariba SNAP Deployment",
  "ERP - SAP Certified Application Associate - SAP Ariba Sourcing",
  "ERP - SAP Certified Application Associate - SAP Ariba Supplier Management",
  "ERP - SAP Certified Application Associate - SAP Ariba Supply Chain Collaboration",
  "ERP - SAP Certified Application Associate - SAP Build Work Zone - Implementation and Administration",
  "ERP - SAP Certified Application Associate - SAP Business Planning and Consolidation 10.1 and 11.0",
  "ERP - SAP Certified Application Associate - SAP BusinessObjects Business Intelligence Platform 4.3",
  "ERP - SAP Certified Application Associate - SAP BusinessObjects Web Intelligence 4.3",
  "ERP - SAP Certified Application Associate - SAP Cloud Platform Integration",
  "ERP - SAP Certified Application Associate - SAP HANA 2.0 (SPS05)",
  "ERP - SAP Certified Application Associate - SAP Master Data Governance",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Asset Management",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Cloud - Enterprise Asset Management",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Cloud - Finance Implementation",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Cloud - Manufacturing Implementation",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Cloud - Procurement Implementation",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Cloud - Sales Implementation",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Cloud - Supply Chain Implementation",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Cloud Implementation with SAP Activate",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Cloud – Professional Services Implementation",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Extended Warehouse Management",
  "ERP - SAP Certified Application Associate - SAP S/4HANA for Financial Accounting Associates",
  "ERP - SAP Certified Application Associate - SAP S/4HANA for Management Accounting Associates",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Sales",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Sales 2020 Upskilling",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Sourcing and Procurement",
  "ERP - SAP Certified Application Associate - SAP S/4HANA Transportation Management",
  "ERP - SAP Certified Application Associate - Treasury with SAP S/4HANA",
  "ERP - SAP Certified Application Associate – SAP S/4HANA Production Planning and Manufacturing",
  "ERP - SAP Certified Application Associate – SAP S/4HANA Sales 2020",
  "ERP - SAP Certified Application Professional - Financials in SAP S/4HANA for SAP ERP Finance Experts",
  "ERP - SAP Certified Application Specialist – SAP BW/4HANA 2.0 Delta",
  "ERP - SAP Certified Associate - Design Thinking",
  "ERP - SAP Certified Associate - SAP Activate Project Manager",
  "ERP - SAP Certified Citizen Developer Associate - SAP Build Low-code/No-code Applications and Automa",
  "ERP - SAP Certified Development Associate",
  "ERP - SAP Certified Development Associate - Programming in S/4H for SAP NetWeaver ABAP Programmers",
  "ERP - SAP Certified Development Associate - SAP Cloud Platform",
  "ERP - SAP Certified Development Associate - SAP Cloud SDK Extensibility Developer",
  "ERP - SAP Certified Development Associate - SAP Fiori Application Developer",
  "ERP - SAP Certified Development Associate - SAP HANA 2.0 SPS05",
  "ERP - SAP Certified Development Associate - SAP Integration Suite",
"ERP - SAP Certified Development Associate – SAP BTP Extensions with SAP Cloud Application Programmin",
"ERP - SAP Certified Development Associate – SAP Cloud for Customer",
  "ERP - SAP Certified Development Specialist - ABAP for SAP HANA 2.0",
  "ERP - SAP Certified Professional - SAP Enterprise Architect",
  "ERP - SAP Certified Technology Associate - SAP HANA 2.0",
  "ERP - SAP Certified Technology Associate - SAP HANA Cloud Provisioning & Administration (C_HCADM_02)",
  "ERP - SAP Certified Technology Associate - SAP S/4HANA System Administration",
  "ERP - SAP Certified Technology Specialist - SAP S/4HANA Conversion and SAP System Upgrade",
  "ERP - SAP S/4HANA Cloud Implementation Fundamentals",
  "Expert in Agile Coaching (ICE-AC)",
  "Expert in Enterprise Coaching (ICP-EC)",
  "Expert in Product Ownership (ICE-PO)",
  "FinOps Practitioner  - Cloud Financial Management",
  "GASF: GIAC Advanced Smartphone Forensics",
  "GAWN: GIAC Assessing and Auditing Wireless Networks",
  "GCCC: GIAC Critical Controls Certification",
  "GCDA: GIAC Certified Detection Analyst",
  "GCED: GIAC Certified Enterprise Defender",
  "GCFA: GIAC Certified Forensic Analyst",
  "GCFE: GIAC Certified Forensic Examiner",
  "GCIA: GIAC Certified Intrusion Analysts",
  "GCIH: GIAC Certified Incident Handler",
  "GCIP: GIAC Critical Infrastructure Protection",
  "GCUX: GIAC Certified UNIX Security Administrator",
  "GCWN: GIAC Certified Windows Security Administrator",
  "GDAT: GIAC Defending Advanced Threats",
  "GDSA: GIAC Defensible Security Architecture",
  "GIAC Cyber Threat Intelligence (GCTI)",
  "GIAC Penetration Tester (GPEN)",
  "GIAC Security Essential Certification (GSEC)",
  "GISF: GIAC Information Security Fundamentals",
  "GISP: GIAC Information Security Professional",
  "GLEG: GIAC Law of Data Security & Investigations",
  "Global Industrial Cyber Security Professional (GICSP)",
  "GMOB: GIAC Mobile Device Security Analyst",
  "GMON: GIAC Continuous Monitoring Certification",
  "GNFA: GIAC Network Forensic Analyst",
  "Governance, Risk and Compliance Professional Certification (GRCP)",
  "Green Software for Practitioners (LFC131)",
  "GREM: GIAC Reverse Engineering Malware",
  "GRID: GIAC Response and Industrial Defense",
  "GSE: GIAC Security Expert",
  "GSLC: GIAC Security Leadership",
  "GSNA: GIAC Systems and Network Auditor",
  "GSTRT: GIAC Strategic Planning, Policy, and Leadership",
  "GWAPT: GIAC Web Application Penetration",
  "GWEB: GIAC Certified Web Application Defender",
  "GXPN: GIAC Exploit Researcher and Advanced Penetration Tester",
  "Implementing SAFe",
  "Information Governance Specialist Certification",
  "ITIL 4 Foundation",
  "ITIL 4 Leader",
  "ITIL 4 Managing Professional Transition Module",
  "ITIL 4 Specialist: Create, Deliver and Support",
  "ITIL 4 Specialist: Sustainability in Digital and IT",
  "ITIL 4 Strategist",
  "ITIL Intermediate Lifecycle: Service Operation",
"ITIL Intermediate – Lifecycle: Design",
  "ITIL v3 Foundation",
  "ITIL® Advanced - Managing Across the Lifecycle",
  "ITIL® Intermediate – Capability: Operational Support and Analysis",
  "ITIL® Intermediate – Capability: Planning, Protection and Optimization",
  "ITIL® Intermediate – Capability: Release, Control and Validation",
  "ITIL® Intermediate – Capability: Service Offerings and Agreements",
  "ITIL® Intermediate – Lifecycle: Continual Service Improvement",
  "ITIL® Intermediate – Lifecycle: Service Transition",
  "ITIL® v3 Intermediate - Lifecycle: Service Strategy",
  "ITIL® v3 Master",
  "ITIL® v3 Practitioner",
  "Kanban System Design (KSD)",
  "Kanban System Improvement (KSI)",
  "Leading SAFe",
  "Lean Portfolio Management",
  "MB-400: Microsoft Power Apps + Dynamics 365 Developer Associate",
  "MCSD: App Builder (deprecated)",
  "MCSE: Cloud Platform and Infrastructure AWS Certified Cloud Practitioner",
  "MCSE: Cloud Platform and Infrastructure AWS Certified Solutions Architect \u0096 Associate Level",
  "MCSE: Cloud Platform and Infrastructure Designing and Implementing Cloud Data Platform Solutions",
  "MCSE: Cloud Platform and Infrastructure Exam: Architecting Microsoft Azure Infrastructure Solutions",
  "MCSE: Cloud Platform and Infrastructure Exam: Developing Microsoft Azure Solutions",
  "MCSE: Cloud Platform and Infrastructure Exam: Implementing Microsoft Azure Infrastructure Solutions",
  "MCSE: Cloud Platform and Infrastructure Microsoft Azure Architect Technologies (AZ-300)",
  "MCSE: Cloud Platform and Infrastructure Microsoft Azure Infrastructure and Deployment (AZ-100)",
  "Microsoft Azure : (70-537) Configuring and Operating a Hybrid Cloud with Microsoft Azure Stack",
  "Microsoft Certified Azure Network Engineer Associate (AZ-700)",
  "Microsoft Certified Power Platform Fundamentals (PL-900)",
  "Microsoft Certified: Azure for SAP Workloads Specialty",
  "Modern Records Management  (MRM)  Master Cerification",
  "MS-500: Microsoft 365 Security Administration",
  "MS-900: Microsoft 365 Fundamentals",
  "MSP – Managing Successful Programs",
  "Nanodegree Data Scientist",
  "Offensive Security Certified Expert (OSCE)",
  "Offensive Security Certified Professional (OSCP)",
  "Offensive Security Exploitation Expert (OSEE)",
  "Oracle Certified Professional Java SE Programmer",
  "P3O – Portfolio, Programme & Project Office",
  "PBA – Professional Business Analyst",
  "PCI Professional Qualification (PCIP)",
  "PI System Architecture, Planning and Implementation",
  "PI Systems administration for IT Professionals",
  "PI Systems administrations for IT Professionals",
  "PL-100: Power Platform App Maker Associate",
  "PL-200: Power Platform Functional Consultant Associate",
  "PL-400: Power Platform Developer Associate",
  "PL-600: Power Platform Solution Architect",
  "PMP – Project Management Professional",
  "PowerBI (Analyzing and Visualizing Data with Microsoft Power BI)",
  "Prince 2 Foundation",
  "Prince 2 Practitioner",
  "Professional Agile Leadership (PAL-I)",
  "Professional Agile Leadership - Evidence Based Management (PAL-EBM)",
  "Professional Program Certification in DevOps",
  "Professional Scrum Developer (PSD I)",
  "Professional Scrum Facilitation Skills (PSFS)",
  "Professional Scrum Master (PSM I)",
  "Professional Scrum Master II (PSM II)",
  "Professional Scrum Product Owner (PSPO I)",
  "Professional Scrum Product Owner Advanced (PSPO II)",
  "Professional Scrum Trainer (PST)",
  "Professional Scrum with Kanban (PSK)",
  "Professional Scrum with User Experience (PSU)",
  "Program Management Professional (PgMP)",
  "Programming in C# (deprecated)",
  "Purple Team Tactics - Adversary Emulation for Breach Prevention & Detection",
  "Red Hat System Administration I (RH124)",
  "Red Hat System Administration II + EX200 (RH135)",
  "Red Hat System Administration III + EX200 & EX300 (RH255)",
  "Registered Agile Coach",
  "Registered Product Owner",
  "Registered Product Owner@Scale",
  "Registered Scrum Master",
  "Registered Scrum@Scale Practitioner",
  "RHCSA Rapid Track Course + EX200 (RH200)",
  "Risk Management for Business Continuity (RMLE 2000)",
  "SAFe Advanced Scrum Master",
  "SAFe Agilist (SA)",
  "SAFe DevOps",
  "SAFe for Architects",
  "SAFe for Teams",
  "SAFe Product Owner / Product Manager",
  "SAFe Program Consultant (SPC)",
  "SAFe Release Train Engineer",
  "SAFe Scrum Master",
  "Salesforce Certified Administrator",
  "Salesforce Certified Advanced Administrator",
  "Salesforce Certified Application Architect",
  "Salesforce Certified Community Cloud Consultant",
  "Salesforce Certified Data Architecture & Management Designer",
  "Salesforce Certified Development Lifecycle and Deployment Designer",
  "Salesforce Certified Field Service Lightning Consultant",
  "Salesforce Certified Identity and Access Management Designer",
  "Salesforce Certified Industries CPQ Developer",
  "Salesforce Certified Integration Architecture Designer",
  "Salesforce Certified Marketing Cloud Consultant",
  "Salesforce Certified Marketing Cloud Email Specialist",
  "Salesforce Certified Omnistudio Consultant",
  "Salesforce Certified Omnistudio Developer",
  "Salesforce Certified Pardot Consultant",
  "Salesforce Certified Pardot Specialist",
  "Salesforce Certified Platform App Builder",
  "Salesforce Certified Platform Developer I",
  "Salesforce Certified Platform Developer II",
  "Salesforce Certified Sales Cloud Consultant",
  "Salesforce Certified Service Cloud Consultant",
  "Salesforce Certified Sharing and Visibility Designer",
  "Salesforce Certified System Architect",
  "Salesforce Certified Tableau CRM & Einstein Discovery Consultant",
  "Salesforce Platform Developer I",
  "SAP Certifications (Data Modelling, Integration, Reporting, Predictive Analytics)",
  "SAP Certified Application Associate - Data Integration with SAP Data Services 4.2",
  "SAP Certified Application Associate - Modeling and Data Acquisition with SAP BW 7.5 po",
  "SAP Certified Application Associate - Reporting, Modeling and Data Acquisition with SAP BW/4HANA",
  "SAP Certified Application Associate - SAP HANA 2.0 (SPS03)",
"SAP Certified Application Associate – SAP Predictive Analytics",
"SAP Certified Development Associate - SAP Customer Data Cloud",
"SAP Certified Development Associate - SAP Fiori Application Developer [C_FIORDEV_20]",
"SAP Certified Development Professional – SAP Commerce Cloud",
  "SAP FI / CO Overview",
  "SAP HR Overview",
  "SAP MM Overview",
  "SAP SD Overview",
  "ServiceNow - Application Developer",
  "ServiceNow - Implementation Specialist",
  "ServiceNow - System Administration",
  "ServiceNow Certified Application Specialist – Performance Analytics",
  "ServiceNow Certified Implementation Specialist - Project Portfolio Management",
  "ServiceNow Certified Implementation Specialist - Risk and Compliance ",
  "ServiceNow Certified Implementation Specialist - Vendor Risk Management",
"ServiceNow Certified Implementation Specialist – Application Portfolio Management",
"ServiceNow Certified Implementation specialist – CSM",
"ServiceNow Certified Implementation Specialist – Discovery",
"ServiceNow Certified Implementation Specialist – Event Management",
"ServiceNow Certified Implementation specialist – HRSD",
"ServiceNow Certified Implementation Specialist – Service Mapping",
"ServiceNow Certified Implementation specialist – SIR",
"ServiceNow Certified Implementation specialist – VR",
  "Team Kanban Practitioner (TKP)",
  "Testing - Automation – Tricentis Tosca",
  "Testing - API Automation - RestSharp",
  "Testing - Certified Card Payment Systems Professional (CCPSP)",
  "Testing - Certified International Payment Systems Professional (CIPSP)",
  "Testing - EMV Masterclass",
  "Testing - ISTQB Agile Tester Foundation",
  "Testing - ISTQB Foundation Level",
  "Testing - ISTQB Test Analyst",
  "Testing - ISTQB Test Automation Engineer",
  "Testing - ISTQB Test Manager",
  "Testing-Endur",
"TOGAF® 9 for Practitioners (Level 1 & 2)",
  "Udacity Nano Degrees Data Science",
  "Visualizing PI System Data with PI Vision",
];
const ANY = [
  ...IT_FUNCTIONAL_SKILLS,
  ...IT_TECHNICAL_SKILLS,
  ...DOMAIN_KNOWLEDGE,
  ...BUSINESS_AND_PROFESSIONAL_EFFECTIVENESS,
  ...IT_SKILLS_CERTIFICATION,
];

const skills = {
  Any: ANY,
  "IT Functional Skills": IT_FUNCTIONAL_SKILLS,
  "IT Technical Skills": IT_TECHNICAL_SKILLS,
  "Domain Knowledge": DOMAIN_KNOWLEDGE,
  "Business and Professional Effectiveness":
    BUSINESS_AND_PROFESSIONAL_EFFECTIVENESS,
  "IT Skills Certification": IT_SKILLS_CERTIFICATION,
};

export default skills;
